import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../../../utils/http";
import { updateToast } from "../../../../utils/toast_notification";
import { User } from "../../../types";

type Payload = {
  emancipationDecisionKey?: string;
  isEmancipatedMinor?: boolean
  email?: string;
  password?: string;
  dateOfBirth?: string;
  tempAppointment?: string | null | undefined;
}

type UrlProps = {
  referrer?: string | null;
  isEmancipatedMinor: boolean
  data: User
}

const URL_SWITCHER = ({ data, isEmancipatedMinor, referrer }: UrlProps) => {
  if (isEmancipatedMinor) {
    return `/patients/verify-email/${data.userId}?referrer=appointment`;
  }

  const emailReferrer = referrer === "appointment" ? "?referrer=appointment" : "";
  return `/patients/verify-email/${data.userId}/${data.email}${emailReferrer}`;
};

export const usePatientSignup = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isEmancipatedMinor, setIsEmancipatedMinor] = useState(false);

  const referrer = new URLSearchParams(search)?.get("referrer");

  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation({
    mutationKey: ['patient signup'],
    mutationFn: async ({ ...credentials }: Payload) => {
      setIsEmancipatedMinor(credentials.isEmancipatedMinor || false)
      const response = await http.post(
        `patients/register${referrer ? "?referrer=appointment" : ""}`,
        credentials, {
        headers: {
          "Content-Type": "application/json",
          Accept: "multipart/form-data"
        }
      })
      return response.data;
    },

    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },

    onSuccess: (data, _variables, context) => {
      const { toastId } = context;

      updateToast(toastId, "Successfully registered. Please check your email to verify your account.", "success");

      queryClient.invalidateQueries({ queryKey: ['provider profile details'] });

      const selectedURL = URL_SWITCHER({ data, isEmancipatedMinor, referrer });

      navigate(selectedURL, { replace: true });

    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      const errorMessage = axios.isAxiosError(error)
        ? error?.response?.data?.error || "Error registering patient"
        : error?.message || "Error registering patient";

      updateToast(toastId, errorMessage, "error");
    }
  });

  return {
    patientSignup: mutate,
    ...rest,
  };
}
