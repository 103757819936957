import clsx from "clsx";
import React from "react";
import { BillerResponseData } from "../../api/hooks/biller/types/biller.types";


interface SPHeaderProps {
  loggedIn?: boolean | null;
  currentUserInfo?: BillerResponseData;
  headerTitle?: string;
}

const SPHeader: React.FC<SPHeaderProps> = ({
  loggedIn = null,
  currentUserInfo,
  headerTitle
}) => {
  return (
    <div
      className={clsx({
        "hidden lg:flex z-10 top-0 items-center justify-between border border-[#EEEEE9] bg-[#FFFFFF] w-full h-[100px] m-auto mt-0 relative shadow-[0px_4.62128px_18.4851px_#EEEEE9]":
          true,
        "bg-[#FBFEF7] border-2 border-[#EEEEE9]": loggedIn
      })}
    >
      <div className="w-full flex items-center justify-between">
        <div className="text-[24px] font-[600] text-[#324705] m-auto w-[100%] ml-3">
          {headerTitle ? headerTitle : <></>}
        </div>
        <div className="w-[300px] flex justify-end items-center mr-4">
          <img
            className="h-[40px] w-[40px] rounded-full mr-6"
            src={currentUserInfo?.user?.avatarUrl || "/assets/images/avatar.png"}
            alt="Profile img"
          />
          <p className="text-[16px] font-[500] text-[#324705]">
            {currentUserInfo && currentUserInfo?.user?.firstName + " "}
            {currentUserInfo && currentUserInfo?.user?.lastName}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SPHeader;
