import { useQuery } from '@tanstack/react-query';
import billerHttp from '../../../utils/billerHttp';
import { ErrorResponse } from '../../types';
import { BillerProvider, BillerProvidersResponse } from './types/biller.types';

export function useGetBillerProvidersTableList() {
  const { data, isLoading, ...rest } = useQuery<BillerProvidersResponse, ErrorResponse>({
    queryKey: ["get all biller providers"],
    queryFn: async () => {
      const response = await billerHttp.get(`billers/providers`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    allProviders: data?.billerProviders as BillerProvider[],
    isLoading,
    ...rest,
  }
}