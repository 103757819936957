
import { format } from 'date-fns';
import { useEffect } from 'react';
import { BillerChargeSheet } from '../../../api/hooks/biller/types/biller.types';
import { BillerFormValues } from '../../schema/claims_for_review.schema';

type Props = {
  initialValues: BillerFormValues
  prefilledValues: BillerChargeSheet | undefined;
  isTableClickedViewMode: boolean;
  setValues: (values: React.SetStateAction<BillerFormValues>) => void;
  values: BillerFormValues;
};

export const useSyncBillerFormValues = ({
  initialValues,
  prefilledValues,
  isTableClickedViewMode,
  setValues,
  values,

}: Props) => {

  useEffect(() => {
    const updatedValues = { ...initialValues };

    if (isTableClickedViewMode) {
      // Update fields based on prefilledValues
      updatedValues.patientName = prefilledValues?.patientName ?? "";
      updatedValues.dateOfBirth = prefilledValues?.patientDateOfBirth
        ? format(new Date(prefilledValues?.patientDateOfBirth), "MM/dd/yyyy")
        : prefilledValues?.patientDateOfBirth ?? "";
      updatedValues.copay = prefilledValues?.patientCoPay ?? "";
      updatedValues.gender = prefilledValues?.patientGender ?? undefined;
      updatedValues.procedureCodes = prefilledValues?.procedureCodes ?? [];
      updatedValues.diagnosisCodes = prefilledValues?.diagnosisCodes ?? [];
      updatedValues.patientInsurance = prefilledValues?.patientPrimaryInsurance ?? "";
      updatedValues.appointmentDate = prefilledValues?.dateOfAppointment ? format(new Date(prefilledValues?.dateOfAppointment), "MM/dd/yyyy") : "" ?? ""
      updatedValues.providerName = prefilledValues?.providerName ?? "";
      updatedValues.appointmentLocation = prefilledValues?.locationOfAppointment ?? ""
      updatedValues.modeOfEncounter = prefilledValues?.modeOfAppointment ?? "virtual";

    }
    if (!isTableClickedViewMode) {

      // Update fields based on prefilledValues in New Billing mode

      updatedValues.providerName = initialValues?.providerName ?? "";
      updatedValues.patientName = initialValues?.patientName;
      updatedValues.dateOfBirth = initialValues?.dateOfBirth
        ? format(new Date(initialValues?.dateOfBirth), "MM/dd/yyyy")
        : initialValues?.dateOfBirth ?? "";
      updatedValues.copay = initialValues?.copay ?? "";
      updatedValues.gender = initialValues?.gender ?? undefined;
      updatedValues.procedureCodes = initialValues?.procedureCodes ?? [];
      updatedValues.diagnosisCodes = initialValues?.diagnosisCodes ?? [];
      updatedValues.patientInsurance = initialValues?.patientInsurance ?? "";
      updatedValues.appointmentDate = initialValues?.appointmentDate ? format(new Date(initialValues?.appointmentDate), "MM/dd/yyyy") : "" ?? ""
      updatedValues.appointmentLocation = initialValues?.appointmentLocation ?? "";
      updatedValues.modeOfEncounter = initialValues?.modeOfEncounter ?? "virtual";
    }




    setValues({
      ...values,
      ...updatedValues
    });
  }, [
    prefilledValues?.patientName,
    prefilledValues?.patientDateOfBirth,
    prefilledValues?.patientGender,
    prefilledValues?.procedureCodes,
    prefilledValues?.diagnosisCodes,
    prefilledValues?.patientPrimaryInsurance,
    prefilledValues?.dateOfAppointment,
    prefilledValues?.modeOfAppointment,
    prefilledValues?.providerName,
    prefilledValues?.locationOfAppointment,
    prefilledValues?.patientCoPay,
    initialValues?.appointmentDate,
    initialValues?.modeOfEncounter,
    initialValues?.patientInsurance,
    initialValues?.patientName,
    initialValues?.dateOfBirth,
    initialValues?.gender,
    initialValues?.copay,
    initialValues?.providerName,
    initialValues?.procedureCodes,
    initialValues?.diagnosisCodes,
    initialValues?.patientInsurance,
    initialValues?.appointmentDate,
    initialValues?.modeOfEncounter,
    isTableClickedViewMode,
    initialValues,
    setValues
  ]);

  return;
};