import { useFormik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAuthState } from "../api/hooks/patient/auth/useAuthState";
import { useCreateDependent } from "../api/hooks/patient/createDependent";
import { useGetPatientDependents } from "../api/hooks/patient/getPatientDependants";
import AddDependantForm from "../components/MyAccount/Patient/AddDependantForm";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import Button from "../components/shared/Button";
import { Loading } from "../components/shared/Loading";
import RadioDiv from "../reuseables/RadioDiv";
import { ArrowBackIcon } from "../svgs/ArrowBackIcon";
import { PlusIcon } from "../svgs/PlusIcon";
import { removeEmptyObjectValues } from "../utils/helpers";

export function ScheduleDepVisit() {
  const { user: patientUser } = useAuthState()
  const [isAddingNewDependant, setIsAddingNewDependant] = useState(false);
  const [selectedDependant, setSelectedDependant] = useState({
    id: "",
    value: "",
    user: ""
  });
  const appLocationState: any = useLocation().state;

  const navigate = useNavigate();

  const { patientDependents, isLoading: isFetchingDependants, isError, error } = useGetPatientDependents();

  const { createDependant, isPending: isCreatingDependant } = useCreateDependent();


  const handleBackClick = () => {
    if (appLocationState) {
      //
      navigate(appLocationState.pageFrom, {
        replace: true,
        state: {
          ...appLocationState
        }
      });
    } else {
      if (!isFetchingDependants) {
        if (isAddingNewDependant) {
          setIsAddingNewDependant(false);
        } else {
          navigate("/dashboard");
        }
      }
    }
  };

  const handleSetAddDep = () => {
    setIsAddingNewDependant(true);
  };

  const handleSelectDep = (value: any) => {
    setSelectedDependant(value);
  };


  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      gender: "",
      dependentRelationship: "",
      dateOfBirth: new Date(),
      dependantId: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid Email"),
      dependentRelationship: Yup.string().required(
        "Dependent relationship is required"
      ),
      dateOfBirth: Yup.string().required("Date of birth is required")
    }),
    onSubmit: async () => {
      const formattedData = removeEmptyObjectValues({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        gender: formik.values.gender,
        dependentRelationship: formik.values.dependentRelationship,
        dateOfBirth: formik.values.dateOfBirth
      });
      createDependant({
        firstName: formattedData?.firstName,
        lastName: formattedData?.lastName,
        email: formattedData?.email,
        gender: formattedData?.gender,
        dependentRelationship: formattedData?.dependentRelationship,
        dateOfBirth: formattedData?.dateOfBirth,
      }, {
        onSuccess: () => {
          formik.resetForm();
        }
      });

    }
  });

  const { values, touched, errors, setFieldValue, handleSubmit, handleChange } = formik;


  if (isFetchingDependants) {
    return <div className="h-1/2 w-1/2 flex justify-center items-center">
      <Loading />
    </div>
  }

  if (isError) {
    toast.error(error?.error)
  }
  return (
    <>
      <DashboardLayout headerTitle="Schedule Appointment">
        <div className="flex justify-between items-center w-[calc(100%-10%)] pr-0 pl-0  mt-[20px]">
          <div className="flex flex-col items-center w-full">
            <div className="w-full">
              <div className="w-fit flex">
                <Button
                  onClick={() => handleBackClick()}
                  icon={<ArrowBackIcon />}
                  iconPosition="left"
                  size="medium"
                  label="Go back"
                  variant="tertiary"
                  additionalClassname="text-[#103C1B]"
                ></Button>
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap lg:grid gap-2 grid-cols-2 grid-rows-2 ml-7 mt-5">
                <div className="full lg:w-[450px]">
                  {isAddingNewDependant ? (
                    <p className="text-[1.5rem] font-normal text-[#103C1B]">
                      What is your dependent details?
                    </p>
                  ) : (
                    <p className="text-[1.5rem] font-normal text-[#103C1B]">
                      Who is this visit for?
                    </p>
                  )}
                </div>
                {isAddingNewDependant ? (
                  <div className="w-full flex flex-col">
                    <AddDependantForm
                      onCloseModal={() => setIsAddingNewDependant(false)}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                      loading={isCreatingDependant}
                    />
                  </div>
                ) : (
                  <div>
                    {isFetchingDependants ? (
                      <>please wait...</>
                    ) : (
                      <div>
                        <div className="w-full flex flex-col mb-3 overflow-y-auto max-h-40 ">
                          <RadioDiv
                            isSelected={
                                  selectedDependant.id === patientUser?.userId
                            }
                            value="myself"
                            label="Myself"
                            onClick={() =>
                              handleSelectDep({
                                id: patientUser?.userId,
                                value: "myself",
                                user: "self"
                              })
                            }
                          />
                              {patientDependents && patientDependents?.user?.length > 0 ? (
                            <>
                                  {patientDependents?.user?.map(({ userId, lastName, firstName }) => (
                                <RadioDiv
                                  key={userId}
                                  isSelected={
                                    selectedDependant.id === userId
                                  }
                                  value={userId}
                                  label={`${firstName} ${lastName}`}
                                  onClick={() =>
                                    handleSelectDep({
                                      id: userId,
                                      value: `${firstName} ${lastName}`,
                                      user: "dependent"
                                    })
                                  }
                                />
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="flex flex-col w-full">
                          <div
                            className="cursor-pointer flex flex-row"
                            onClick={handleSetAddDep}
                          >
                            <PlusIcon />
                            <span className="text-[#1A9D39] text-[18px] ml-3">
                              Add new dependents
                            </span>
                          </div>
                          <div className="mt-3 text-[#6E8877] text-[19px]">
                            <p>
                              If you schedule a visit for your dependent, they
                              can join from your account.
                            </p>
                          </div>
                          <div className="w-full flex justify-end align-center mt-5">
                            <Button
                              size="medium"
                              variant="primary"
                              label="Continue"
                                  loading={isCreatingDependant}
                              onClick={() => {
                                if (appLocationState?.desc !== null) {
                                  if (appLocationState?.desc === "continue") {
                                    navigate(appLocationState?.pageFrom, {
                                      state: {
                                        data: {
                                          dependentId: selectedDependant?.id,
                                          currentPage: 1
                                        }
                                      }
                                    });
                                  } else {
                                    navigate(
                                      `/dashboard/dependent/visit/schedule/${selectedDependant?.id}?user=${selectedDependant?.user}`,
                                      {
                                        state: appLocationState
                                      }
                                    );
                                  }
                                } else {
                                  navigate(
                                    `/dashboard/dependent/visit/schedule/${selectedDependant?.id}?user=${selectedDependant?.user}`
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}
