
import { Fragment, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useProviderAuthState } from '../../../api/hooks/provider/auth/useProviderAuthState';

export const RequireProviderAuth = ({
	children,
}: {
	children: ReactNode;
}) => {
	const location = useLocation();
	const { isAuthenticated } = useProviderAuthState();
	const authenticated = isAuthenticated();

	if (!authenticated) {
		return (
			<Fragment>
				<Navigate to='/provider/login' state={{ from: location }} replace />
			</Fragment>
		);
	}

	return <>{children}</>;
};
