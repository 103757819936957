import axios from "axios";
import http from "../utils/http";
import { Specialty } from "./types";

const getPreviouslyUsedProviders = async () => {
  try {
    const response = await http.get("appointments/patient/previous-providers");
    if (response.status === 200) {
      return response.data;
    }
    // throw response.error;
  } catch (err) {
    return err;
  }
};

export const getPatientAppointmentsList = async () => {
  try {
    const response = await http.get("appointments/patient");

    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    let errorMessage = "An unknown error occurred ";

    if (axios.isAxiosError(error)) {
      if (error.response) {
        // Server responded with a status other than 2xx
        errorMessage = `Error: ${error.response.status} - ${
          error.response.data?.error || error.message
        }`;
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage = "No response received from server";
      } else {
        // Something happened in setting up the request
        errorMessage = error.message;
      }
    } else {
      return errorMessage + error?.message;
    }

    return { success: false, message: errorMessage };
  }
};

export const getSinglePatientAppointmentDetails = async (id: string) => {
  try {
    const response = await http.get(`appointments/patient/${id}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const getSinglePatientPrescriptiontDetails = async (
  prescriptionId: number
) => {
  try {
    const response = await http.get(`patients/prescription/${prescriptionId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const getPatientPrescriptionsList = async () => {
  try {
    const response = await http.get("prescriptions/patient");
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const getSingleProviderAppointments = async (providerId: string) => {
  try {
    const response = await http.get(
      `appointments/provider?providerId=${providerId}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
type ParamsProps = {
  specialty: Specialty;
  dependentId?: string;
};
export const getUsedProvidersBasedOnSpecialty = async ({
  specialty,
  dependentId
}: ParamsProps) => {
  const queryString = dependentId
    ? `appointments/patient/previous-providers?specialty=${specialty}&dependentId=${dependentId}`
    : `appointments/patient/previous-providers?specialty=${specialty}`;
  try {
    const response = await http.get(queryString);
    if (response.status === 200) {
      return response.data;
    }
    // throw response.error;
  } catch (err) {
    return err;
  }
};

export const updatePatientDetails = async (params: any) => {
  try {
    const response = await http.get("/patients/details", { params });
    if (response.status === 200) {
      return response.data;
    }
    // throw response.error;
  } catch (err) {
    return err;
  }
};

export const getPatientBookedProviders = async () => {
  try {
    const response = await http.get("patients/providers/booked");
    if (response.status === 200) {
      return response.data.patientsProviders;
    }
  } catch (error) {
    return error;
  }
};

export { getPreviouslyUsedProviders };
