import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { updateToast } from "../../../utils/toast_notification";

type Payload = {
  patientId: string;
  appointmentDate: string;
  appointmentStartTime: string;
  isInitialAppointment: boolean | string;
  appointmentType: string;
}

export const useBookProviderAppointments = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['book provider appointment'],
    mutationFn: async ({ ...data }: Payload) => {
      const { patientId, ...theRest } = data;
      const response = await http.post(`appointments/patient?patientId=${patientId}`,
        {
          ...theRest
        })
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },

    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;

      updateToast(toastId, "Appointment booked successfully", "success");

      queryClient.invalidateQueries({ queryKey: ['provider upcoming appointments'] })
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number;

      const errorMessage = axios.isAxiosError(error) ? error?.response?.data?.error || error.response?.statusText : error?.message || "Error booking appointment"

      updateToast(toastId, errorMessage, "error");

    },
  });

  return {
    bookAppointmentsForProvider: mutate,
    ...rest,
  };
}