import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TextField from '../../components/form/TextField';
import Button from '../../components/shared/Button';
import Spacer from '../../components/Spacer';


interface InsuranceFormProps {
  setInsuranceName: React.Dispatch<React.SetStateAction<{
    payer_id: string;
    payer_name: string;
  }>>
  insuranceName: {
    payer_id: string;
    payer_name: string;
  }
  setShowPersonalIdInput: React.Dispatch<React.SetStateAction<boolean>>
  showPersonalIdInput: boolean;
  setInsuranceData: React.Dispatch<React.SetStateAction<any[]>>
  insuranceData: any[];
  isSelected: boolean;
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>
  handleSkip: () => void;
  setFormData: React.Dispatch<any>
  formData: any
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export function InsuranceForm({
  setFormData,
  insuranceName,
  setInsuranceName,
  setShowPersonalIdInput,
  setInsuranceData,
  insuranceData,
  isSelected,
  setIsSelected,
  handleSkip,
  showPersonalIdInput,
  formData,
  setCurrentPage
}: InsuranceFormProps) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        insuranceName: "",
        insuranceNumber: ""
      }}
      validationSchema={Yup.object().shape({
        insuranceName: Yup.string().required(
          "Insuance type is required"
        ),
        insuranceNumber: Yup.string().required(
          "Insurance Id is required "
        )
      })}
      onSubmit={(values) => { }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue
      }) => (
        <Form className="flex flex-col mx-auto r-0 items-center w-full lg:w-[550px] overflow-scroll h-full pb-[50px]">
          <div className="flex flex-col items-center mx-auto w-full">
            <div className="mt-4 w-full">
              <label
                className="text-[#000] font-medium mb-2 text-[16px] block"
                htmlFor='insuranceName'
              >
                Insurance
              </label>
              <div>
                <div className="">
                  <TextField
                    name="insuranceName"
                    type="text"
                    placeholder="Enter the insurance name here "
                    value={values.insuranceName}
                    onChange={e => {
                      handleChange(e)
                      setIsSelected(false);
                      setInsuranceData([]);
                      setInsuranceName({
                        ...insuranceName,
                        payer_name: e.target.value
                      });
                    }}
                  />
                </div>
                {/* {touched.insuranceName && errors.insuranceName && (
                          <div className="text-[red] text-xs">
                            {errors.insuranceName}
                          </div>
                        )} */}
                <div className="flex flex-col gap-2">
                  {!isSelected &&
                    insuranceData.map((item, index) => (
                      <div
                        key={index}
                        className=""
                        onClick={() => {
                          setFieldValue(
                            "insuranceName",
                            item.payer_name
                          );
                          setInsuranceName({
                            payer_name: item.payer_name,
                            payer_id: item.payerid
                          });
                          setIsSelected(true);
                          setShowPersonalIdInput(true);
                        }}
                      >
                        {item.payer_name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {showPersonalIdInput && (
              <div className="mt-4 w-full">
                <TextField
                  name="insuranceNumber"
                  type="text"
                  placeholder="Personal ID"
                  value={values.insuranceNumber}
                  onChange={handleChange}
                  label="Insurance ID"
                />
              </div>
            )
            }
            <div className="mt-4 w-full flex flex-row justify-end item-center">
              <Button
                label="Skip"
                variant="secondary"
                width="100px"
                height="40px"
                onClick={() => handleSkip()}
              />
              <Spacer width={10} />
              <Button
                label="Continue"
                variant="primary"
                width="100px"
                height="40px"
                onClick={async () => {
                  handleSubmit();
                  setFormData({
                    ...formData,
                    ...values
                  });
                  setInsuranceName({
                    payer_name: values.insuranceName,
                    payer_id: insuranceName.payer_id
                  });
                  setCurrentPage(prev => prev + 1);
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>

  );
}