import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import billerHttp from "../../../utils/billerHttp";
import { getDecodedJwt, setBillerToken } from "../../../utils/helpers";
import { BillerUser } from "./types/biller.types";

type Payload = {
  email: string;
  password: string;
}

type Props = {
  setLoginError: Dispatch<SetStateAction<string>>
}

export const usePostBillerLogin = ({ setLoginError }: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.path || "/biller/dashboard"

  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['biller login'],
    mutationFn: async ({ email, password }: Payload) => {
      const response = await billerHttp.post('billers/login', {
        email,
        password
      })
      return response.data;
    },

    onSuccess: ({ token }) => {
      if (token) {
        setBillerToken(billerHttp, token);
        const decoded = getDecodedJwt<BillerUser>(token);
        if (!decoded?.data?.hasSeenLoginModal) {
          navigate("/biller/register");
        } else {
          navigate(from, {
            replace: true
          });
        }
      }
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        setLoginError(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        setLoginError(
          error?.message
          || "Error tryng to login",
        );
      }
    },
  });

  return {
    billerLogin: mutate,
    variables,
    ...rest,
  };
}
