import { useDeleteDependant } from "../../../api/hooks/patient/deleteDepentant";
import Button from "../../../components/shared/Button";
import { CancelIcon } from "../../../svgs/CancelIcon";
import ModalPortal from "../../shared/ModalPortal";

interface IProps {
  dependantId: string;
  closeModal: () => void;
  isVisible: boolean;
  dependantName: string;
}
const DeleteDependantModal: React.FC<IProps> = ({
  closeModal,
  isVisible = false,
  dependantName,
  dependantId
}) => {

  const { deleteDependant, isPending } = useDeleteDependant()

  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
      >
        <div className="flex justify-end  w-[90%] lg:w-[462px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white h-[315px] w-[90%] lg:w-[462px] px-4 pt-5 pb-4 lg:p-6 lg:pb-4 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600]">
            Delete Dependent
          </p>
          <div className="flex flex-col pl-4 pr-3 items-center justify-center h-full mt-[50px]">
            <div className="mt-3 text-center lg:mt-0 lg:ml-4 lg:text-left w-full mb-4">
              <p className="text-[#103C1B] text-[18px] font-[400] text-left">
                Are you sure you want to remove {dependantName} as your
                dependent?
              </p>
            </div>

            <div className="hidden lg:flex justify-center items-center mt-4 m-auto w-full">
              <div className="flex mr-[10px] underline">
                <Button
                  variant="secondary"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                  }}
                  size="medium"
                />
              </div>
              <div className="flex ml-[14px]">
                <Button
                  type="button"
                  variant="primary"
                  label="Yes, delete it"
                  loading={isPending}
                  // additionalClassname="w-[109px]"
                  onClick={() => deleteDependant({ dependantId })}
                  size="medium"
                />
              </div>
            </div>

            <div className="flex lg:hidden  justify-center items-center mt-4 m-auto w-full">
              <div className="flex mr-[10px]">
                <Button
                  variant="secondary"
                  label="Cancel"
                  onClick={() => {
                    closeModal();
                  }}
                  size="small"
                />
              </div>
              <div className="flex ml-[14px]">
                <Button
                  type="button"
                  variant="primary"
                  label="Delete it"
                  onClick={() => deleteDependant({ dependantId })}
                  loading={isPending}
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  ) : null;
};

export default DeleteDependantModal;
