import { format } from "date-fns";
import { Fragment, useState } from "react";
import { AppointmentAvailability } from "../../api/types";
import { LocationIcon } from "../../svgs/PhysicalMeetingIcon";
import { VideoIcon } from "../../svgs/VideoIcon";
import { formatCurrency, truncateText } from "../../utils/helpers";
import Chip from "../Chip";
import SingleProviderFullProfileDrawer from "../SingleProviderFullProfileDrawer";
import Spacer from "../Spacer";


type ProviderObject = {
  availableSlots: AppointmentAvailability[]
  avatarUrl: string
  firstName: string
  lastName: string
  providerId: string
  acceptsVirtualAppointments: boolean
  acceptsPhysicalAppointments: boolean
  isInitialAppointment: boolean
  initialAppointmentFee: number
  followUpAppointmentFee: number
  title: string
  bio: string
}

interface SingleProviderCardProps {
  providerObject: ProviderObject;
  availabilityText?: string | null;
  onChipClick?: (arg: any) => any;
}

const SingleProviderCard = ({
  providerObject,
  availabilityText = null,
  onChipClick = () => { }
}: SingleProviderCardProps) => {
  const {
    availableSlots,
    avatarUrl,
    firstName,
    lastName,
    providerId,
    acceptsVirtualAppointments,
    acceptsPhysicalAppointments,
    isInitialAppointment,
    initialAppointmentFee,
    followUpAppointmentFee,
    title: providerType,
    bio: description
  } = providerObject;
  const [showFullProfileDrawer, setShowFullProfileDrawer] = useState(false);
  const formatAvailableSlots = availableSlots.reduce((acc: any, curr: any) => {
    curr.appointmentTimes.forEach((value: string) =>
      acc.push({ [curr.appointmentDate]: value })
    );
    return acc;
  }, []);

  return (
    <Fragment>
      <div className="flex flex-col justify-start items-center border border-[#EBEBEB] rounded-lg bg- w-full max-w-[606px] h-fit bg-gradient-to-b-[rgba(0, 0, 0, .36), rgba(0, 0, 0, .36)] grow shadow-md mb-4 p-4 px-5">
        <div className="w-full flex flex-row items-center justify-between">
          <div className="flex justify-start items-center">
            <img
              src={avatarUrl || "/assets/images/avatar.png"}
              alt=""
              className="rounded-full w-[80px] h-[80px]"
            />
            <div className="w-8" />
            <div className="flex flex-col">
              <p className="text-[26px] font-[400] text-[#103C1B]">
                {`${firstName} ${lastName}`}
              </p>
              <div className="h-1" />
              <div className="flex flex-row items-center space-x-2 text-[15px] font-[400] text-[#103C1B]">
                <p>{providerType || ""}</p>
                <div className="flex flex-row rounded-sm font-semibold text-center">
                  {acceptsVirtualAppointments && (
                    <VideoIcon height={24} width={24} />
                  )}
                  <Spacer width={10} />
                  {acceptsPhysicalAppointments && (
                    <LocationIcon width={19} height={19} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-1">
            <div className="text-[16px] font-[400] text-[#103C1B]">
              {isInitialAppointment
                ? " Initial Appointment Fee"
                : " Follow Up Appointment Fee"}
            </div>
            <div className="text-[20px] font-[500] text-[#103C1B]">
              {formatCurrency.format(isInitialAppointment
                ? initialAppointmentFee ?? 0
                : followUpAppointmentFee ?? 0)
              }
            </div>
          </div>
        </div>
        <div className="h-4" />
        {description && (
          <p className="w-full text-[#103C1B] text-[400] text-[15px]">
            {truncateText(description)}
          </p>
        )}
        <div className="h-4" />
        <p className="w-full text-left text-[#87928A] text-[400] text-[15px]">
          {availabilityText ? availabilityText : "Next Available Slots"}
        </p>
        <div className="h-2" />
        {/* TODO map through data here */}
        <div className="w-full flex items-center justify-start">
          <div className="flex justify-start overflow-x-scroll flex-row w-[100%]">
            {formatAvailableSlots.map((a: any, idx: number) => (
              <div className="cursor-pointer mr-4" key={idx}>
                <Chip
                  labelBold={`${format(
                    new Date(Object.keys(a)[0]),
                    "E dd/MM"
                  )},`}
                  label={`${format(
                    new Date(`2023/3/29 ${`${Object.values(a)[0]}`}`),
                    "p"
                  )}`}
                  width={185}
                  value={[Object.values(a)[0]]}
                  onClick={() => {
                    onChipClick({
                      selectedTime: Object.values(a)[0],
                      selectedDate: Object.keys(a)[0],
                      providerId
                    })
                  }

                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className="h-4" />
        <p
          onClick={() => setShowFullProfileDrawer(true)}
          className="w-full text-left text-[#1A9D39] font-[400] text-[15px] cursor-pointer"
        >
          Check Full profile and availability
        </p>
      </div>
      <SingleProviderFullProfileDrawer
        drawerTitle="Full profile and availability"
        onClose={() => setShowFullProfileDrawer(false)}
        open={showFullProfileDrawer}
        data={providerObject}
        availability={availableSlots}
        onTimeSlotClick={({ date, time, providerId }) => {
          onChipClick({
            selectedTime: time,
            selectedDate: date,
            providerId
          });
        }}
      />
    </Fragment>
  );
};

export default SingleProviderCard;
