import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { PatientUser } from "../../../api/hooks/patient/type/patient";
import CustomDrawer from "../../drawer";
import EditDependantForm from "./EditDependantForm";

interface EditDependantDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
  dependantDetails: PatientUser;
}

type InitialValues = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dateOfBirth: Date;
  dependantId?: string;
  dependentRelationship: string;
}

const EditDependantDrawer: React.FC<EditDependantDrawerProps> = ({
  drawerTitle,
  onClose,
  open,
  dependantDetails
}) => {
  const [initialFormValues, setInitialFormValues] = useState<InitialValues>({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    dateOfBirth: new Date(),
    dependantId: "",
    dependentRelationship: ""
  });


  useEffect(() => {
    setInitialFormValues({
      firstName: dependantDetails?.firstName,
      lastName: dependantDetails?.lastName,
      email: dependantDetails?.email,
      gender: dependantDetails?.gender,
      dateOfBirth: dependantDetails?.dateOfBirth
        ? new Date(dependantDetails?.dateOfBirth)
        : new Date(),
      dependantId: dependantDetails?.userId,
      dependentRelationship: dependantDetails?.dependentRelationship

    });
  }, [dependantDetails]);

  const formik = useFormik({
    initialValues: {
      firstName: initialFormValues?.firstName,
      lastName: initialFormValues?.lastName,
      email: initialFormValues?.email,
      gender: initialFormValues?.gender,
      dateOfBirth: initialFormValues?.dateOfBirth
        ? new Date(initialFormValues?.dateOfBirth)
        : new Date(),
      dependantId: initialFormValues?.dependantId,
      dependentRelationship: dependantDetails?.dependentRelationship
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid Email"),
      phoneNumber: Yup.string().required("Phone number is required"),
      gender: Yup.string().required("Gender is required"),
      dateOfBirth: Yup.string().required("Date of birth is required")

    }),
    onSubmit: () => { }
  });

  const { values, touched, errors, setFieldValue, handleSubmit, handleChange } = formik;

  return (
    <div className="w-full lg:w-[600px]">
      <CustomDrawer
        noWidth
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
      >
        <EditDependantForm
          onCloseModal={onClose}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange} />
      </CustomDrawer>
    </div>
  );
};

export default EditDependantDrawer;
