import { styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useProviderAuthState } from "../../../api/hooks/provider/auth/useProviderAuthState";
import ProviderHeader from "./ProviderHeader";
import ProviderMobileHeader from "./ProviderMobilerHeader";
import ProviderRetractibleSidebar from "./ProviderRetractibleSidebar";
import ProviderSidebar from "./ProviderSideBar";

interface ProviderDashboardLayoutProps {
  children: React.ReactNode;
  headerTitle?: string;
}

const ProviderDashboardLayout: React.FC<ProviderDashboardLayoutProps> = ({
  children,
  headerTitle
}) => {
  const { user: currentUserObject } = useProviderAuthState()
  const [isRetractableMenuOpen, setIsRetractableMenuOpen] = useState(false);

  const pageTop = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <StyledProviderDashboardContainer>
      <div className="sidebar">
        <ProviderSidebar />
        <ProviderRetractibleSidebar
          isMenuOpen={isRetractableMenuOpen}
          dismiss={() => {
            setIsRetractableMenuOpen(false);
          }}
        />
      </div>
      <div className="header z-9999">
        <ProviderHeader
          loggedIn={!!currentUserObject}
          currentUserInfo={currentUserObject}
          headerTitle={headerTitle}
        />
        <ProviderMobileHeader
          openRetractableMenu={() => {
            setIsRetractableMenuOpen(!isRetractableMenuOpen);
          }}
          headerTitle={headerTitle}
        />
        <div ref={pageTop} />
      </div>
      <div className="body h-screen" style={{ overflow: "auto" }}>
        {children}
      </div>
    </StyledProviderDashboardContainer>
  );
};
const StyledProviderDashboardContainer = styled("div")(() => ({
  width: "100%",
  height: "100vh",
  position: "relative",
  display: "grid",
  gap: "6px",
  gridTemplateColumns: "20% 75%",
  gridTemplateRows: "10% 90%",
  gridTemplateAreas:
    "'sidebar header header header header' \n 'sidebar body  body body body'",
  " @media (max-width: 1023px)": {
    gridTemplateAreas:
      "' header header header header sidebar' \n ' body  body body body sidebar'"
  },

  "& .sidebar": {
    gridArea: "sidebar",
    width: "100%",
    height: "100%",
    position: "relative",
    zIndex: 100
  },
  "& .header": {
    gridArea: "header",
    width: "100%",
    height: "110px",
    zIndex: 99
  },
  "& .body": {
    gridArea: "body",
    width: "100%",
    height: "100%",
    marginBottom: "20px",
    position: "relative"
  }
}));

export default ProviderDashboardLayout;
