import { useQueries } from '@tanstack/react-query';
import http from '../../../utils/http';

type Props = {
  formType: Array<string>;
  providerId: string;
}

export function useNoAuthGetProviderForm({ formType, providerId }: Props) {
  const data = useQueries({
    queries: formType?.map((noteType) => {
      return {
        queryKey: [`get ${noteType}`, providerId ?? ''],
        queryFn: async () => {

          const response = await http.get(
            `/notes/open?noteType=${noteType}&providerId=${providerId}`)
          return response.data.form ?? []

        },
        concurrent: true,
        enabled: !!providerId,
      }
    })
  });
  return {
    providerFormData: data || [],

  }
}