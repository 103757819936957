import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ListTabsWidget from "../../../components/shared/ListTabsWidget";
import ProviderDashboardLayout from "../../components/dashboard/ProviderDashboardLayout";
import Calendar from "./Calendar";
import Notifications from "./Notifications";
import Patient from "./Patient";
import Payment from "./Payment";
import Templates from "./Templates";
import YourAccount from "./YourAccount";


const Settings = () => {
  const search = useSearchParams()[0];
  const navigate = useNavigate();
  const activeTab = search.get("activeTab");
  const [activeTabLabel, setActiveTabLabel] = useState(
    activeTab || "Your Account"
  );

  const handleTabClick = (tabName: string) => {
    navigate(`/provider/settings?activeTab=${tabName}`);
    setActiveTabLabel(tabName);
  };

  return (
    <ProviderDashboardLayout headerTitle="Settings">
      <div className="lg:mt-6 flex flex-col justify-end w-full h-full">
        <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
          <ListTabsWidget
            tabs={[
              { label: "Your Account" },
              { label: "Calendar" },
              { label: "Patient" },
              { label: "Notifications" },
              { label: "Payment" },
              { label: "Template" }
            ]}
            handleTabClick={label => {
              handleTabClick(label);
            }}
            activeTabLabel={activeTabLabel}
          />
        </div>

        {(activeTab === "Your Account" || activeTab === null) && (
          <YourAccount />
        )}
        {activeTab === "Calendar" && (
          <Calendar />
        )}
        {activeTab === "Patient" && (
          <Patient />
        )}

        {activeTab === "Notifications" && (
          <Notifications />
        )}

        {activeTab === "Payment" && (
          <Payment />
        )}

        {activeTab === "Template" && <Templates />}
      </div>
    </ProviderDashboardLayout>
  );
};

export default Settings;
