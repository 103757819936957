import { useQuery } from '@tanstack/react-query';
import { ProviderAvailabilityData } from '../../../provider/components/types/newAppointment';
import http from '../../../utils/http';
import { ErrorResponse } from '../../types';


export function useGetProvidersAppointments() {
  const { data, isLoading, refetch, ...rest } = useQuery<ProviderAvailabilityData, ErrorResponse>({
    queryKey: ["providers appointments"],
    queryFn: async () => {
      const response = await http.get(`appointments/provider`);
      return response.data;
    }, 
    staleTime: Infinity,
  });

  return {
    availableAppointments: data as ProviderAvailabilityData || [],
    isLoading,
    refetchPPatient: refetch,
    ...rest,
  }
}
