import { FormikErrors, FormikTouched } from 'formik';
import { FC } from "react";
import DatePicker from "react-datepicker";
import { dependentRelationshipList } from "../../../utils/consts";
import Spacer from "../../Spacer";
import RadioField from "../../form/RadioField";
import TextField from "../../form/TextField";
import Button from "../../shared/Button";

type ValuesProps = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dateOfBirth: Date;
  dependantId?: string;
  dependentRelationship: string;
}

interface AddDependantFormProps {
  values: ValuesProps;
  errors: FormikErrors<ValuesProps>;
  touched: FormikTouched<ValuesProps>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onCloseModal: () => void;
  loading: boolean
}

const AddDependantForm: FC<AddDependantFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  setFieldValue,
  onCloseModal,
  loading
}) => {


  return (
    <div className="max-h-[100vh] flex flex-col overflow-y-auto px-8">
      <form onSubmit={handleSubmit}>
        <div className="w-full flex flex-row items-center justify-between my-4">
          <div className="form-item-data">
            <TextField
              name="firstName" 
              type="text"
              placeholder="First name"
              value={values.firstName ?? ""}
              onChange={handleChange}
              label="First Name"
              error={touched.firstName && !!errors.firstName ? errors.firstName : undefined}
            />
          </div>
          <div className="w-2" />
          <div className="form-item-data">
            <TextField
              name="lastName"
              type="text"
              placeholder="Last name"
              value={values.lastName ?? ""}
              onChange={handleChange}
              label="Last Name"
              error={touched.lastName && !!touched.lastName ? errors.lastName : undefined}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="flex flex-row items-center mx-auto w-[100%] gap-5">
            <div className="flex flex-col items-start w-full">
              <label
                htmlFor="dependentRelationship"
                className="text-[#000] font-medium mb-2 text-[16px]"
              >
                Dependent relationship
              </label>
              <select
                id="dependentRelationship"
                className="w-full py-3.5 px-4 border border-[#949589] text-[#6E8877] rounded leading-tight focus:outline-none focus:bg-none focus:border-gray-500 hover:border-black text-s"
                // placeholder="Select dependent relationship"
                name="dependentRelationship"
                value={values.dependentRelationship ?? ""}
                onChange={e => handleChange(e)}
              >
                <option value="" disabled>
                  Select dependent relationship
                </option>
                {dependentRelationshipList.map((relationType, index) => {
                  return (
                    <option key={index} value={relationType ?? ""} id={relationType}>
                      {relationType}
                    </option>
                  );
                })}
              </select>
              {touched.dependentRelationship &&
                errors.dependentRelationship && <Spacer height={10} />}
              {touched.dependentRelationship &&
                errors.dependentRelationship && (
                  <div className="text-[red] text-xs">
                  {typeof errors.dependentRelationship === 'string' ? errors.dependentRelationship : null}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="form-item-text">Email</div>
          <div className="flex flex-row items-center justify-end">
            <div className="w-full">
              <TextField
                name="email"
                type="email"
                placeholder="Enter email"
                value={values.email ?? ""}
                onChange={handleChange}
                error={touched.email ? (typeof errors.email === 'string' ? errors.email : '') : undefined}
              />
            </div>
          </div>
        </div>
        <div className="my-4">
          <div className="form-item-text">Date Of Birth</div>
          <div className="flex flex-row items-center justify-end">
            <div className="w-full bg-white border border-[#CECFC5]">
              <DatePicker
                selected={values.dateOfBirth ?? ""}
                name="dateOfBirth"
                dateFormat="MM/dd/yyyy"
                onChange={(date: Date) => {
                  setFieldValue("dateOfBirth", date);
                }}
                placeholderText="MM/DD/YYYY"
                className="date-picker"
                forceShowMonthNavigation={true}
                showYearDropdown={true}
                showMonthDropdown={true}
                scrollableMonthYearDropdown={true}
                yearDropdownItemNumber={100}
                scrollableYearDropdown={true}
                shouldCloseOnSelect={true}
                required
              />
              <>
                {touched.dateOfBirth && errors.dateOfBirth && (
                  <p className="mt-2 text-xs text-red-600 capitalize">
                    Date of birth is required
                  </p>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="form-item-grid">
          <div className="form-item-text">Gender</div>
          <div className="flex flex-row items-center justify-start">
            <div className="form-item-data">
              <RadioField
                name="gender"
                placeholder="Gender"
                display="block"
                options={[
                  { item: "Male", value: "male" },
                  { item: "Female", value: "female" }
                ]}
                hasOther={true}
                isRequired={true}
                value={values.gender ?? ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end my-4">
          <Button
            height={60}
            onClick={() => onCloseModal()}
            // onClick={(val) => {
            //   console.log({ val });

            //   updateDependent({
            //     firstName: values.firstName,
            //     lastName: values.lastName,
            //     email: values.email,
            //     gender: values.gender,
            //     dateOfBirth: values.dateOfBirth.toISOString(),
            //     dependentRelationship: values.dependentRelationship,
            //     dependantId: values.dependantId ? values.dependantId : '',
            //   });
            //   onCloseModal()
            // }}
            type="submit"
            variant="primary"
            label="Add Dependent"
            size="medium"
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default AddDependantForm;
