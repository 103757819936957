import { useQuery } from "@tanstack/react-query";
import http from "../../../utils/http";
import { ErrorResponse, UserResponse } from "../../types";


export function useGetProviderProfileDetails() {
  const { data, isLoading, refetch, ...rest } = useQuery<UserResponse, ErrorResponse>({
    queryKey: ["provider profile details"],
    queryFn: async () => {
      const response = await http.get(`providers/details`);
      return response.data;
    }
  });
  return {
    providerData: data as UserResponse,
    isLoading,
    refetch,
    ...rest,
  }
}