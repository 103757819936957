import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse, FormResponse } from '../../types';

export function useGetPatientFormResponse({ appointmentId }: { appointmentId: string }) {
  const { data, isLoading, refetch, ...rest } = useQuery<FormResponse, ErrorResponse>({
    queryKey: ["patient record response", appointmentId as string],
    queryFn: async () => {
      const response = await http.get(`/documentation/appointment/${appointmentId}?noteType=intakeForm`);
      return response.data;
    },
    enabled: !!appointmentId,
  });
  return {
    patientFormResponseData: data as FormResponse,
    isLoading,
    refetchPatientFormResponse: refetch,
    ...rest,
  }
}
