import { useFormik } from "formik";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { useUpdateEmergencyContact } from "../../../api/hooks/patient/updateEmergencyContact";
import CustomDrawer from "../../drawer";
import RadioField from "../../form/RadioField";
import TextField from "../../form/TextField";
import Button from "../../shared/Button";

interface EditEmergencyContactDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
  contactDetails: any;
}

const EditEmergencyContactDrawer = ({
  drawerTitle,
  onClose,
  open, 
  contactDetails
}: EditEmergencyContactDrawerProps) => {
  const [initialFormValues, setInitialFormValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: new Date(),
    relationship: ""
  });

  const firstname = contactDetails?.name?.split(" ")[0];
  const lastname = contactDetails?.name?.split(" ")[1];

  useEffect(() => {
    setInitialFormValues({
      firstName: firstname,
      lastName: lastname,
      email: contactDetails?.email,
      phoneNumber: contactDetails?.phoneNumber,
      gender: contactDetails?.gender,
      dateOfBirth: contactDetails?.dateOfBirth
        ? new Date(contactDetails?.dateOfBirth)
        : new Date(),
      relationship: contactDetails?.relationship
    });
  }, [contactDetails, firstname, lastname]);

  const formik = useFormik({
    initialValues: {
      firstName: initialFormValues?.firstName,
      lastName: initialFormValues?.lastName,
      email: initialFormValues?.email,
      phoneNumber: initialFormValues?.phoneNumber,
      gender: initialFormValues?.gender,
      dateOfBirth: initialFormValues?.dateOfBirth
        ? new Date(initialFormValues?.dateOfBirth)
        : new Date(),
      relationship: initialFormValues?.relationship
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      dateOfBirth: Yup.string().required("Date of birth is required"),
      relationship: Yup.string().required("Relationship is required")
    }),
    onSubmit: () => {}
  });

  const { updateEmergencyContact, isPending } = useUpdateEmergencyContact();

  return (
    <div className="w-full lg:w-[600px]">
      <CustomDrawer
        noWidth
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
      >
        <div className="max-h-[100vh] flex flex-col overflow-y-auto px-8">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full flex flex-row items-center justify-between my-4">
              <div className="form-item-data">
                <TextField
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  label="First Name"
                />
              </div>
              <div className="w-2" />
              <div className="form-item-data">
                <TextField
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  label="Last Name"
                />
              </div>
            </div>
            <div className="form-item-grid">
              <div className="form-item-text">Gender</div>
              <div className="flex flex-row items-center justify-start">
                <div className="form-item-data">
                  <RadioField
                    name="gender"
                    placeholder="Gender"
                    display="inline"
                    options={[
                      { item: "Male", value: "male" },
                      { item: "Female", value: "female" }
                    ]}
                    hasOther={true}
                    isRequired={true}
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Email</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full">
                  <TextField
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Phone Number</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full">
                  <TextField
                    name="phoneNumber"
                    type="tel"
                    placeholder="Enter phone"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Date Of Birth</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full bg-white border border-[#CECFC5]">
                  <DatePicker
                    selected={formik.values.dateOfBirth || ""}
                    name="dateOfBirth"
                    dateFormat="MM/dd/yyyy"
                    onChange={(date: Date) => {
                      formik.setFieldValue("dateOfBirth", date);
                    }}
                    placeholderText="MM/DD/YYYY"
                    className="date-picker"
                    forceShowMonthNavigation={true}
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    scrollableMonthYearDropdown={true}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    shouldCloseOnSelect={true}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="my-4">
              <div className="form-item-text">Relationship</div>
              <div className="flex flex-row items-center justify-end">
                <div className="w-full">
                  <TextField
                    name="relationship"
                    type="text"
                    placeholder="Relationship"
                    value={formik.values.relationship}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end my-4">
              <Button
                height={60}
                loading={isPending}
                onClick={async () => {
                  const contact = formik?.values?.firstName.concat(
                    " ",
                    formik?.values?.lastName
                  );
                  updateEmergencyContact(
                    {
                      name: contact,
                      email: formik.values.email,
                      phoneNumber: formik.values.phoneNumber,
                      gender: formik.values.gender,
                      dateOfBirth: formik.values.dateOfBirth,
                       relationship: formik.values.relationship,
                       contactId: contactDetails?.contactId
                     }
                   );
                  onClose();
                }}
                type="submit"
                variant="primary"
                label="Save"
                size="medium"
              />
            </div>
          </form>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default EditEmergencyContactDrawer;
