import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import axios from "axios";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import { useState } from "react";
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { Fragment } from "react/jsx-runtime";
import { getProviderAppointment } from "../../api/hooks/patient/getProviderAppointment";
import { AllProviderAppointment, AppointmentAvailability, ProviderDetails } from "../../api/types";
import SingleProviderCard from "../../components/cards/SingleProviderCard";
import { Loading } from '../../components/shared/Loading';
import Spacer from "../../components/Spacer";
import { ReactComponent as EmptySMG } from "../../svgs/empty-smg.svg";
import { SelectedAppointmentDetails } from "../ScheduleVisitInsurance";


type ScheduleFormProps = {
  loading: boolean
  doctorAppointments: AllProviderAppointment | null;
  setSelectedAppointmentDetails: React.Dispatch<React.SetStateAction<SelectedAppointmentDetails | null>>
  handleScheduleTime: (value: any) => void
  setDoctorAppointments: React.Dispatch<React.SetStateAction<AllProviderAppointment | null>>
  setIsFetchingDoctorAppointments: React.Dispatch<React.SetStateAction<boolean>>
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<string>>
}

type QueryParams = {
  specialty: string;
  date?: string;
  time?: string;
  radius?: number;
}

export function ScheduleForm({
  loading,
  doctorAppointments,
  setSelectedAppointmentDetails,
  handleScheduleTime,
  setDoctorAppointments,
  setIsFetchingDoctorAppointments,
  setIsError,
  setError
}: ScheduleFormProps) {
  const location: Location = useLocation();
  const navigate = useNavigate();

  const [selectedTime, setSelectedTime] = useState<Date | null>(null);

  const debouncedSubmit = debounce((submitForm) => {
    submitForm();
  }, 700);

  const query = new URLSearchParams(location.search);
  const user = new URLSearchParams(location.search).get('user');
  const specialty = new URLSearchParams(location.search).get('specialty') ?? location.state?.data?.visiting;

  const initialDate = query.get('date') ?? '';
  const initialTime = query.get('time') ?? '';
  const initialDistance = query.get('radius') ? Number(query.get('radius')) : 0;

  return (
    <div className="w-full">
      <Fragment>
        <div className="h-[24px]" />
        <Formik
          initialValues={{
            date: initialDate,
            time: initialTime,
            distance: initialDistance,
          }}
          onSubmit={async values => {
            try {
              setIsFetchingDoctorAppointments(true)
              const queryParams: QueryParams = {
                specialty: location.state?.data?.visiting ?? "pcp",
              };
              const queryParam = new URLSearchParams();

              if (values.date) queryParams["date"] = values.date;
              if (values.time) queryParams["time"] = values.time;
              if (values.distance) queryParams["radius"] = values.distance;

              if (values.date) queryParam.set('date', values.date);
              else queryParam.delete('date');
              if (values.time) queryParam.set('time', values.time);
              else queryParam.delete('time');
              if (values.distance) queryParam.set('radius', values.distance.toString());
              else queryParam.delete('radius');
              // url starts from specialty=specialty&user=user&date=date&time=time&radius=radius
              navigate(`${location.pathname}?specialty=${specialty}&user=${user}&${queryParam.toString()}`, {
                replace: true
              });

              const data = await getProviderAppointment(queryParams);
              setDoctorAppointments(data);

              setIsFetchingDoctorAppointments(false)
            } catch (error) {
              setIsFetchingDoctorAppointments(false)
              if (axios.isAxiosError(error)) {
                setIsError(true)
                setError(error.response?.data?.error)
              }
            }
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Form className="flex flex-col items-center w-full lg:w-[600px] pr-2">
              <div className="hidden lg:flex items-center justify-end lg:w-full mb-5">
                <div className="w-full pr-2">
                  <p className="mb-2 text-[#6E8877]">Date</p>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Select Date"
                      value={values.date ? new Date(values.date) : null}
                      minDate={new Date(values.date)}
                      format="yyyy-MM-dd"
                      onChange={(date) => {
                        const formattedDate = format(new Date(date as Date), "yyyy-MM-dd");
                        setFieldValue("date", formattedDate);
                        if (formattedDate) {
                          debouncedSubmit(handleSubmit);
                        }
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="w-full ">
                  <p className="mb-2 text-[#6E8877]">Time</p>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      ampm={false}
                      label="Select Time"
                      value={values.time ? new Date(`1970-01-01T${values.time}:00`) : null}
                      format="HH:mm"
                      onAccept={(time) => {
                        const selectedTimeSlot = format(new Date(time as Date), "HH:mm");
                        setFieldValue("time", selectedTimeSlot);
                        if (selectedTimeSlot) {
                          debouncedSubmit(handleSubmit);

                        }
                      }}
                      onChange={(time) => {
                        setSelectedTime(time);
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="w-full ml-2">
                  <p className="text-[#6E8877]">Distance from you</p>
                  <div className="w-full mt-3 ">
                    <input
                      className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                      value={values.distance}
                      placeholder="Select distance"
                      type="number"
                      onChange={value => {
                        setFieldValue("distance", value.target.value);
                        if (value) {
                          debouncedSubmit(handleSubmit);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-12 block lg:hidden mb-5">
                <div className="w-full flex items-center">
                  <div className="w-1/2 pr-2">
                    <p className="mb-2 text-[#6E8877]">Date</p>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Select Date"
                        value={selectedTime}
                        minDate={new Date(values.date)}
                        format="yyyy-MM-dd"
                        onChange={(date) => {
                          const formattedDate = format(new Date(date as Date), "yyyy-MM-dd");
                          setFieldValue("date", formattedDate);
                          if (formattedDate) {
                            debouncedSubmit(handleSubmit);

                          }
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="w-1/2 ">
                    <p className="mb-2 text-[#6E8877]">Time</p>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        ampm={false}
                        label="Select Time"
                        value={selectedTime}
                        format="HH:mm"
                        onAccept={(time) => {
                          const selectedTimeSlot = format(new Date(time as Date), "HH:mm");
                          setFieldValue("time", selectedTimeSlot);
                          if (selectedTimeSlot) {
                            debouncedSubmit(handleSubmit);
                          }
                        }}
                        onChange={(time) => {
                          setSelectedTime(time);

                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="w-full mt-3">
                  <p className="text-[#6E8877]">Distance from you</p>
                  <div className="w-full mt-3 ">
                    <input
                      className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                      value={values.distance}
                      placeholder="Select distance"
                      type="number"
                      onChange={v => {
                        setFieldValue("distance", v.target.value);
                        handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="w-full">
          {loading ? (
            <div className="w-1/2 h-1/2 flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <Fragment>

                {Object.keys(doctorAppointments?.providersAvailability ?? {})?.length <= 0 ? (
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <EmptySMG />
                  <Spacer height={10} />
                  <h2 className="text-[#103C1B] text-[18px] font-bold">
                    No doctor available at this time
                  </h2>
                  <Spacer height={10} />
                  <p className="text-center text-[#103C1B] text-[16px]">
                    We are sorry, please choose another time
                    prefererence.
                  </p>
                </div>
              ) : (
                <div className="w-full mb-3 sm:mb-0 flex flex-col items-end justify-end lg:w-[606px] pr-5">
                      {Object.keys(doctorAppointments?.providersAvailability ?? {})?.map(
                    (key: string, index) => (
                      <SingleProviderCard
                        key={index}
                        onChipClick={data => {
                          setSelectedAppointmentDetails({
                            ...doctorAppointments?.providersAvailability[key]?.details,
                            ...data,
                            selectedDate: data.selectedDate || '',
                            selectedTime: data.selectedTime || '',
                            isInitialAppointment: true
                          });
                          handleScheduleTime(data);
                        }}
                        providerObject={{
                          ...doctorAppointments?.providersAvailability[key]?.details as ProviderDetails,
                          availableSlots: doctorAppointments?.providersAvailability[key]?.availability as AppointmentAvailability[],
                          isInitialAppointment: true
                        }}
                      />
                    )
                  )}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </Fragment>
    </div>
  )
}
