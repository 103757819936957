import { differenceInYears, format } from "date-fns";
import { Form, Formik } from "formik";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useUpdateProviderDetails } from "../../api/hooks/provider/updateProviderDetails";
import { LicenseNumber } from "../../api/types";
import Spacer from "../../components/Spacer";
import RadioField from "../../components/form/RadioField";
import TextField from "../../components/form/TextField";
import TextFieldWithTags from "../../components/form/TextFieldWithTags";
import Button from "../../components/shared/Button";
import { DeleteIcon } from "../../svgs/DeleteIcon";
import { InfoIcon } from "../../svgs/InfoIcon";
import { statesInTheUS } from "../../utils/consts";
import { removeEmptyKeys } from "../../utils/removeEmptyFromObjectOrArray";


const LicenseFormInputInitialState: LicenseNumber[] = [
  { number: "", state: "" }
];

type FormValues = {
  dateOfBirth: Date;
  providerNPI: string;
  specialty: string;
  deaNumber: string;
  deaState: string;
  title: string;
  practiceAddress: string;
  hasAWebsite: boolean;
  hasRegisteredPractice: boolean;
  hasMalpracticeInsurance: boolean;
  acceptsCash?: boolean;
  acceptsInsurance?: boolean;
  focusAreas: string[];
  state: "";
  city: "";
  zipCode: "";
  practiceNPI: "";
  practiceName: "";
};

const ProfessionalDetails = () => {
  const isExistingPractice = sessionStorage.getItem("isExistingPractice");
  const [licenseFormInputs, setLicenseFormInputs] = useState<
    LicenseNumber[]
  >(LicenseFormInputInitialState);
  const usaStates = statesInTheUS;
  const [tagInputValue, setTagInputValue] = useState("");
  const [formData, setFormData] = useState<FormValues>({
    dateOfBirth: new Date(),
    providerNPI: "",
    specialty: "",
    deaNumber: "",
    deaState: "",
    title: "",
    practiceAddress: "",
    hasAWebsite: false,
    hasRegisteredPractice: false,
    hasMalpracticeInsurance: false,
    focusAreas: [],
    state: "",
    city: "",
    zipCode: "",
    practiceNPI: "",
    practiceName: ""
  });

  const navigate = useNavigate();
  const specialtyList: Array<any> = [
    { name: "Primary Care Provider", value: "pcp" },
    { name: "Psychiatrist", value: "psychiatrist" },
    { name: "Therapist", value: "therapist" }
  ];

  const handleTagsInputChange = (event: any) => {
    setTagInputValue(event.target.value);
  };

  const { updateProviderProfileDetails, isPending } = useUpdateProviderDetails();

  const activateButton = ({
    criteria,
    formData
  }: {
    criteria: Array<string>;
    formData: any;
  }) => {
    let disabled = false;
    for (const item of criteria) {
      if (typeof formData[item] === "string") {
        if (!formData[item] || formData[item].length <= 0) {
          disabled = true;
        }
      }
    }
    return disabled;
  };

  const handleRemoveTag = (index: number) => {
    setFormData((prevStates: FormValues) => ({
      ...prevStates,
      focusAreas: prevStates?.focusAreas.filter((el, i) => i !== index)
    }));
  };
  // function isDateDifferenceGreaterThan17Days(
  //   inputDate: Date | undefined
  // ): boolean {
  //   if (inputDate === undefined) return false; // If inputDate is undefined, return false (as there's no date to compare)

  //   const currentDate = new Date();
  //   const dateDifferenceInMilliseconds =
  //     currentDate.getTime() - inputDate.getTime();
  //   const dateDifferenceInDays =
  //     dateDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

  //   return dateDifferenceInDays > 17;
  // }
  return (
    <section className="my-10 mx-[5%] sm:mx-[10%] lg:mx-[30%] flex flex-col items-center">
      <Formik
        initialValues={{
          dateOfBirth: formData.dateOfBirth ?? new Date(),
          providerNPI: formData?.providerNPI ?? "",
          specialty: formData?.specialty ?? "",
          deaNumber: formData?.deaNumber ?? "",
          deaState: formData?.deaState ?? "",
          practiceAddress: formData?.practiceAddress ?? "",
          hasAWebsite: formData?.hasAWebsite ?? false,
          hasRegisteredPractice: formData?.hasRegisteredPractice ?? false,
          hasMalpracticeInsurance: formData?.hasMalpracticeInsurance ?? false,
          focusAreas: formData?.focusAreas,
          state: formData?.state,
          city: formData?.city,
          zipCode: formData?.zipCode,
          practiceNPI: formData?.practiceNPI,
          practiceName: formData?.practiceName
        }}
        validationSchema={Yup.object({
          specialty: Yup.string().required("Specialty is required"),
          deaNumber: Yup.string().matches(
            /^[A-Z][A-Z0-9]\d{7}$/,
            "DEA Number must start with two uppercase letters followed by seven digits"
          ),
          providerNPI: Yup.string()
            .required("Provider NPI is required")
            .matches(
              /^\d{10}$/,
              "Invalid NPI, Provider NPI must be 10 digit number"
            ),
          practiceNPI: Yup.string().matches(
            /^\d{10}$/,
            "Invalid NPI, practice NPI must be 10 digit number"
          ),
          dateOfBirth: Yup.string()
            .required("DOB is required")
            .test(
              "DOB",
              "Provider must be older than 18 to register",
              value => {
                return value
                  ? differenceInYears(new Date(), new Date(value)) >= 18
                  : false;
              }
            )
        })}
        onSubmit={async values => {
          const { practiceAddress, ...rest } = values;
          const formattedKeys = removeEmptyKeys(rest);
          updateProviderProfileDetails({
            ...formattedKeys,
            ...(practiceAddress && { address: practiceAddress })
          }, {
            onSuccess: (data) => {
              localStorage.setItem("temple_user_token", data.token);
              navigate("/provider/dashboard", { state: { from: "/professional-details" }});
            },

          });

        }}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          setFieldValue,
          handleSubmit,
          handleBlur,
          setFieldTouched
        }) => (
          <Form>
            <div className="w-full pb-[30%]">
              <div className="text-[20px]">
                To complete your account registration, we need you to fill in
                your professional details and credentials
              </div>
              {/* <p className="head-subtext">
                Enter your professional details and credentials for verification
                and accurate representation
              </p> */}
              {/* {Object.keys(formError).length > 0 && (
                <div
                  className={
                    formError.type === "error"
                      ? "error-container mt-4"
                      : "error-container-warning mt-4"
                  }
                >
                  <span className="text-[13px] font-medium">
                    {formError.error}
                  </span>
                </div>
              )} */}

              {isExistingPractice && (
                <div className="my-5 w-[100%]">
                  <TextField
                    name="practiceName"
                    type="text"
                    placeholder="John Doe Medical Center"
                    value={values?.practiceName}
                    onChange={handleChange}
                    label="Practice Name"
                    onBlur={handleBlur}
                    error={touched.practiceName ? errors.practiceName : ""}
                  />
                </div>
              )}

              <div className="w-full my-5">
                <p className="text-[#000] font-medium mb-2 text-[16px]">
                  Date of birth
                </p>

                <div className="w-full bg-white border border-[#CECFC5]">
                  <ReactDatePicker
                    selected={new Date(values?.dateOfBirth)}
                    name="dateOfBirth"
                    dateFormat="MM/dd/yyyy"
                    onChange={(v: any) => {
                      setFieldValue(
                        "dateOfBirth",
                        format(new Date(v), "MM/dd/yyyy")
                      );
                      const currentDate = new Date();
                      const age = differenceInYears(
                        currentDate,
                        values.dateOfBirth
                      );
                    }}
                    placeholderText="MM/DD/YYYY"
                    className="date-picker"
                    shouldCloseOnSelect={true}
                    showYearDropdown={true}
                    scrollableYearDropdown={true}
                    dropdownMode="select"
                    required={true}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="text-red-600">
                  {touched.dateOfBirth ? <> {errors?.dateOfBirth ?? ""}</> : ""}
                </div>
              </div>

              <div className="my-5">
                <p className="text-[#000] font-medium mb-2 text-[16px]">
                  Specialty
                </p>
                <select
                  className="w-[100%] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                  // placeholder="Select an option"
                  name="specialty"
                  value={values?.specialty}
                  onChange={handleChange}
                  required={true}
                  onBlur={handleBlur}
                >
                  <option value="" disabled>
                    Select Specialty
                  </option>
                  {specialtyList.map((list, idx) => (
                    <option value={list.value} key={idx}>
                      {list.name}
                    </option>
                  ))}
                </select>
                {errors.specialty && touched.specialty ? (
                  <p className="form-error">{errors.specialty}</p>
                ) : (
                  ""
                )}
              </div>

              <TextFieldWithTags
                value={tagInputValue}
                tags={formData?.focusAreas}
                handleKeyDown={event => {
                  if (event.key === "Enter" && tagInputValue.trim() !== "") {
                    setFormData((prevStates: FormValues) => ({
                      ...prevStates,
                      focusAreas: [
                        ...prevStates?.focusAreas,
                        tagInputValue.trim()
                      ]
                    }));

                    setFieldValue("focusAreas", [
                      ...formData?.focusAreas,
                      tagInputValue.trim()
                    ]);

                    setTagInputValue("");
                  }
                }}
                handleInputChange={e => handleTagsInputChange(e)}
                handleRemoveTag={handleRemoveTag}
                label="Focus areas"
              />

              <div className="my-5 w-[100%]">
                <TextField
                  name="providerNPI"
                  type="text"
                  placeholder="093039203"
                  value={values?.providerNPI}
                  onChange={handleChange}
                  label="Provider NPI"
                  error={touched.providerNPI ? errors.providerNPI : ""}
                  isRequired={true}
                  onBlur={handleBlur}
                  showAsterisk={true}
                />
              </div>
              {(isExistingPractice ||
                (isExistingPractice && values.hasRegisteredPractice)) && (
                  <div className="my-5 w-[100%]">
                    <TextField
                      name="practiceNPI"
                      type="text"
                      placeholder="7605648952"
                      value={values?.practiceNPI}
                      onChange={handleChange}
                      label="Practice NPI"
                      error={touched.practiceNPI ? errors.practiceNPI : ""}
                      onBlur={handleBlur}
                    />
                  </div>
                )}

              <div>
                {licenseFormInputs &&
                  licenseFormInputs.map((input, index) => {
                    return (
                      <div
                        className="w-full p-2 flex justify-start items-center"
                        key={index}
                      >
                        <div className="w-[45%]">
                          <TextField
                            name="licenseNumber"
                            type="text"
                            placeholder="121231"
                            value={input?.number}
                            onChange={async e => {
                              const newArr: LicenseNumber[] = [
                                ...licenseFormInputs
                              ];
                              newArr[index].number = e.target.value;

                              setLicenseFormInputs(newArr);

                              setFieldValue("licenseNumbers", newArr);
                            }}
                            label={`License Number ${index + 1}`}
                            isRequired={true}
                            showAsterisk={true}
                            onBlur={handleBlur}
                          />
                        </div>
                        <Spacer width={20} />
                        <div className="w-[45%]">
                          <p className="text-[#000] font-medium mb-2 text-[16px]">
                            {`License State  ${index + 1}`}
                          </p>
                          <select
                            className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                            // placeholder="Select an option"
                            name="licenseState"
                            value={input?.state}
                            onBlur={handleBlur}
                            onChange={async e => {
                              const newArr: LicenseNumber[] = [
                                ...licenseFormInputs
                              ];
                              newArr[index].state = e.target.value;

                              setLicenseFormInputs(newArr);
                              setFieldValue("licenseNumbers", newArr);
                            }}
                            required={true}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {usaStates.map(state => (
                              <option value={state.toLowerCase()} key={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Spacer width={20} />
                        <button
                          type="button"
                          className="mt-5 cursor-pointer"
                          onClick={() => {
                            const newInputs = [...licenseFormInputs];
                            newInputs.splice(index, 1);
                            setLicenseFormInputs(newInputs);
                            const newStates: string[] = [];
                            const newNumbers: string[] = [];
                            newInputs?.map(item => {
                              newStates.push(item.state);
                              setFieldValue("licenseStates", newStates);
                              newNumbers.push(item.number);
                              setFieldValue("licenseNumbers", newNumbers);
                              return item;
                            });
                          }}
                        >
                          <DeleteIcon color="grey" />
                        </button>
                      </div>
                    );
                  })}
              </div>

              <button
                type="button"
                className="text-[#1A9D39] text-[16px] ml-3"
                onClick={() => {
                  setLicenseFormInputs([
                    ...licenseFormInputs,
                    { number: "", state: "" }
                  ]);
                }}
              >
                Add new fields
              </button>

              <div className="relative flex flex-row justify-between w-[100%] mt-5 mb-5">
                <div className="w-[50%]">
                  <TextField
                    name="deaNumber"
                    type="text"
                    placeholder="121231"
                    value={values?.deaNumber}
                    onChange={handleChange}
                    label="DEA Number"
                    onBlur={handleBlur}
                    error={touched.deaNumber ? errors.deaNumber : ""}
                  />
                </div>
                <Spacer width={20} />
                <div className="w-[50%]">
                  <p className="text-[#000] font-medium mb-2 text-[16px]">
                    DEA State
                  </p>
                  <select
                    className="w-[100%] py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                    // placeholder="Select an option"
                    name="deaState"
                    value={values?.deaState}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {usaStates.map(state => (
                      <option value={state.toLowerCase()} key={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {(isExistingPractice ||
                (isExistingPractice && values.hasRegisteredPractice)) && (
                  <>
                    <div className="mb-5">
                      <TextField
                        name="practiceAddress"
                        type="text"
                        placeholder="Enter practice address"
                        value={values?.practiceAddress}
                        onChange={handleChange}
                        label="Practice Address"
                        error={
                          touched.practiceAddress ? errors.practiceAddress : ""
                        }
                      />
                    </div>
                    <div className="mb-5">
                      <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
                        <div className="flex flex-col flex-grow items-stretch justify-start">
                          <label
                            htmlFor="state"
                            className="text-[#000] font-medium mb-2 text-[16px]"
                          >
                            State
                          </label>
                          <select
                            className=" w-full px-4 py-3 text-[#6E8877] border border-grey focus:outline-none text-s rounded"
                            // placeholder="Select state"
                            name="state"
                            id="state"
                            value={values.state}
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Select state
                            </option>
                            {statesInTheUS.map((state, index) => {
                              return (
                                <option key={index} value={state} id={state}>
                                  {state}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="">
                          <TextField
                            name="city"
                            type="text"
                            placeholder="New York"
                            value={values.city}
                            onChange={handleChange}
                            label="City"
                            error={touched.city ? errors.city : ""}
                          />
                        </div>
                        <div className="">
                          <TextField
                            name="zipCode"
                            type="text"
                            placeholder="10012"
                            value={values.zipCode}
                            onChange={handleChange}
                            label="Zip Code"
                            error={touched.zipCode ? errors.zipCode : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <div className="mt-4">
                <RadioField
                  name="hasRegisteredPractice"
                  placeholder="Have you registered your practice?"
                  label="Have you registered your practice?"
                  display="inline"
                  options={[
                    { item: "Yes", value: true },
                    { item: "No", value: false }
                  ]}
                  hasOther={false}
                  isRequired={true}
                  value={values?.hasRegisteredPractice}
                  onChange={e => {
                    if (e.target.value === "true") {
                      setFieldValue("hasRegisteredPractice", true);
                    } else {
                      setFieldValue("hasRegisteredPractice", false);
                    }
                  }}
                  error={
                    touched.hasRegisteredPractice
                      ? errors.hasRegisteredPractice
                      : ""
                  }
                />
              </div>
              {!values?.hasRegisteredPractice && (
                <div className="w-full p-[10px] bg-[#EEF6FD] rounded-[8px] flex items-start my-4">
                  <div className="mr-4 pt-[4px]">
                    <InfoIcon />
                  </div>
                  <p className="text-[16px] break-all">
                    Click this link for guidance on registering your practice:
                    <br></br>
                    <a
                      className="underline font-semibold"
                      rel="noreferrer"
                      target="_blank"
                      href="https://blog.templehs.com/business-registration-for-healthcare-providers/"
                    >
                      {" "}
                      https://blog.templehs.com/business-registration-for-healthcare-providers/
                    </a>
                  </p>
                </div>
              )}

              <div className="mt-4">
                <RadioField
                  name="hasAWebsite"
                  placeholder="Do you have a website?"
                  label="Do you have a website?"
                  display="inline"
                  options={[
                    { item: "Yes", value: true },
                    { item: "No", value: false }
                  ]}
                  hasOther={false}
                  isRequired={true}
                  value={values?.hasAWebsite}
                  onChange={e => {
                    if (e.target.value === "true") {
                      setFieldValue("hasAWebsite", true);
                    } else {
                      setFieldValue("hasAWebsite", false);
                    }
                  }}
                  onBlur={handleBlur}
                  error={touched.hasAWebsite ? errors.hasAWebsite : ""}
                />
              </div>

              {!values?.hasAWebsite && (
                <div className="w-full p-[10px] bg-[#EEF6FD] rounded-[8px] flex items-start my-4">
                  <div className="mr-4 pt-[4px]">
                    <InfoIcon />
                  </div>
                  <p className="text-[16px] break-all">
                    <p>
                      For expert guidance on launching your healthcare website,
                      click this link:
                    </p>
                    <br></br>
                    <a
                      className="underline font-semibold"
                      rel="noreferrer"
                      target="_blank"
                      href="https://blog.templehs.com/how-to-open-a-website-as-a-healthcare-provider/"
                    >
                      {" "}
                      https://blog.templehs.com/how-to-open-a-website-as-a-healthcare-provider/
                    </a>
                  </p>
                </div>
              )}

              <div className="mt-4">
                <RadioField
                  name="hasMalpracticeInsurance"
                  placeholder="Do you have Malpractice insurance?"
                  label="Do you have Malpractice insurance?"
                  display="inline"
                  options={[
                    { item: "Yes", value: true },
                    { item: "No", value: false }
                  ]}
                  hasOther={false}
                  isRequired={true}
                  value={values?.hasMalpracticeInsurance}
                  onChange={e => {
                    if (e.target.value === "true") {
                      setFieldValue("hasMalpracticeInsurance", true);
                    } else {
                      setFieldValue("hasMalpracticeInsurance", false);
                    }
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.hasMalpracticeInsurance
                      ? errors.hasMalpracticeInsurance
                      : ""
                  }
                />
              </div>

              {!values?.hasMalpracticeInsurance && (
                <div className="w-full p-[10px] bg-[#EEF6FD] rounded-[8px] flex items-start my-4">
                  <div className="mr-4 pt-[4px]">
                    <InfoIcon />
                  </div>
                  <p className="text-[16px] break-all">
                    Click this link to find a comprehensive guide on setting up
                    your malpractice insurance:
                    <br></br>
                    <a
                      className="underline font-semibold"
                      rel="noreferrer"
                      target="_blank"
                      href="https://blog.templehs.com/business-registration-for-healthcare-providers/"
                    >
                      {" "}
                      https://blog.templehs.com/business-registration-for-healthcare-providers/
                    </a>
                  </p>
                </div>
              )}
              <div className="flex flex-row items-center justify-end w-100 mt-10">
                <Button
                  onClick={() => handleSubmit()}
                  height={60}
                  type="button"
                  variant="primary"
                  label="Save"
                  disabled={
                    isPending ||
                    activateButton({
                      criteria: [
                        "specialty",
                        "practiceName",
                        "dateOfBirth",
                        "licenseNumbers",
                        "licenseStates",
                        "providerNPI",
                        "zipCode"
                      ],
                      formData: values
                    })
                  }
                  size="medium"
                  additionalClassname="!w-[150px]"
                  loading={isPending}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ProfessionalDetails;
