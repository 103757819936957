import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { bookAppointmentsForPatients } from "../api/appointments";
import Button from "../components/shared/Button";
import errorIcon from "../svgs/error_icon.svg";
import successIcon from "../svgs/success-icon.svg";
import http from "../utils/http";
import { isObjectNotEmpty } from "../utils/isObjectEmpty";

const Checkout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState("Your Schedule Appointment Request Failed");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const removeRecords = () => {
    localStorage.removeItem("bookingDetails");
    localStorage.removeItem("medRecords");
    localStorage.removeItem("book_appointment_data");
  };

  const confirmPayment = async () => {
    if (sessionId) {
      const appointmentData = JSON.parse(
        localStorage.getItem("book_appointment_data") ?? "{}"
      );

      if (isObjectNotEmpty(appointmentData)) {
        try {
          const response = await http.post(`stripe/retrieve-session`, {
            session: sessionId
          });
          if (response.status === 200) {
            if(appointmentData?.providerId){
              const res = await bookAppointmentsForPatients(appointmentData);
            if (res?.appointmentDetails) {
              removeRecords();
              setIsLoading(false);
              setIsSuccess(true);
            } else {
              removeRecords();
              if (res.error === "Appointment already booked") {
                setIsLoading(false);
                setIsSuccess(true);
              } else {
                setMsg("Unable to book appointment");
                setIsLoading(false);
                setIsSuccess(false);
              }
            }
            }
          }
        } catch (err: any) {
          setIsLoading(false);
          setIsSuccess(false);
        }
      }
    } else {
      setMsg(`No session id found`);
      setIsLoading(false);
      setIsSuccess(false);
    }
  };
  useEffect(() => {
    confirmPayment();
  }, []);

  return (
    <div className="min-w-screen min-h-screen flex flex-col items-center justify-center space-y-3">
      {loading ? (
        <CircularProgress color="success"></CircularProgress>
      ) : isSuccess ? (
        <div className="text-center flex flex-col items-center justify-center space-y-3">
          {" "}
          <img src={successIcon} alt="success" />
          <p className="text-[#103C1B] text-[30px] font-[400]">Success!</p>
          <p className="text-[#103C1B] text-[18px] font-[400]">
            Your Appointment Has Being Scheduled Successfully
          </p>
          <Button
            variant="primary"
            label="Appointments"
            size="medium"
            onClick={() => navigate("/appointments")}
          />
        </div>
      ) : (
        <div className="text-center flex flex-col items-center justify-center space-y-3">
          {" "}
          <img src={errorIcon} alt="error" className="h-[100px] w-[100px]" />
          <p className="text-[#103C1B] text-[30px] font-[400]">Error!</p>
          <p className="text-[#103C1B] text-[18px] font-[400]">{msg}</p>
          <Button
            variant="primary"
            label="Try Again"
            size="medium"
            onClick={() => navigate("/dashboard/dependent/visit/schedule")}
          />
        </div>
      )}
    </div>
  );
};

export default Checkout;
