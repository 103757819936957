import React from "react";

interface TextFieldWithTagsProps {
  label: string;
  value: string;
  tags: string[];
  handleKeyDown: (e: any) => void;
  handleInputChange: (e: any) => void;
  handleRemoveTag: (i: number) => void;
}

const TextFieldWithTags: React.FC<TextFieldWithTagsProps> = ({
  label,
  value,
  tags,
  handleKeyDown,
  handleInputChange,
  handleRemoveTag
}) => {

  return (
    <div>
      <div className="text-[18px] font-[500] text-left">{label}</div>
      <div className=" w-full border border-[#949589] p-[.5em] rounded-[3px] mt-[2px] flex items-center flex-wrap gap-[.5em]">
        {tags?.map((tag, index) => (
          <div
            key={index}
            className="bg-[#e6faebff] inline-block py-[.5em] px-[.75em] rounded-[6px] text-[#3B3D24] "
          >
            <span key={index}>{tag}</span>
            <span
              className="h-[20px] w-[20px] text-[#3B3D24] inline-flex justify-center items-center ml-[.5em] font-[20px] cursor-pointer"
              onClick={() => handleRemoveTag(index)}
            >
              &times;
            </span>
          </div>
        ))}
        <input
          type="text"
          value={value}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          className="flex-grow-1 py-[.5em] border-none outline-none w-[250px]"
          placeholder="Type something & press enter"
        />
      </div>
    </div>
  );
};

export default TextFieldWithTags;
