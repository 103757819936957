import http from "../../../utils/http";


export const getProviderAppointment = async (queryData: any) => {
  try {
    const response = await http.get("appointments/providers", {
      params: queryData,
      paramsSerializer: function paramsSerializer(params: any) {
        return Object.entries(Object.assign({}, params))
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
      }
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return err;
  }
};