import { differenceInYears, format } from "date-fns";
import { useFormik } from "formik";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { usePatientSignup } from "../../api/hooks/provider/auth/patientRegister";
import PasswordField from "../form/PasswordField";
import TextField from "../form/TextField";
import Button from "../shared/Button";
import Spacer from "../Spacer";
import { signupFormOneinitialValues, signupFormOneSchema } from "./schema/data.schema";

const SignupFormOne = () => {
  const [signupError, setSignupError] = useState("");
  const [dateError, setDateError] = useState("");
  const [isMinor, setIsMinor] = useState(true);
  const [canSignup, setCanSignup] = useState(false);
  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const referrer = searchParams.get("referrer");

  const loginLink = referrer === "appointment" ? "/patient/login?referrer=appointment" : "/patient/login";

  const { patientSignup, isPending } = usePatientSignup()

  const formik = useFormik({
    initialValues: signupFormOneinitialValues,
    validationSchema: signupFormOneSchema,
    onSubmit: async () => {
      if (dateError.length > 0) {
        setDateError("Please enter a valid date");
      } else {
        setDateError("");
      }
      setSignupError("");
      if (isMinor && canSignup) {
        navigate("/signup-minor", {
          state: {
            email: formik.values.email,
            password: formik.values.password,
            dateOfBirth: format(formik.values.dateOfBirth, "MM/dd/yyyy")
          }
        });
      } else if (canSignup) {
        try {
          patientSignup({
            email: formik.values.email,
            password: formik.values.password,
            dateOfBirth: format(formik.values.dateOfBirth, "MM/dd/yyyy"),
            ...(referrer === "appointment" && { referrer: "appointment" }),
            ...(referrer === "appointment" && {
              tempAppointment: localStorage.getItem("bookingDetails")
            })
          },
            {
              onSuccess: () => {
                setSignupError("");
              },

            });

        } catch (e) {
          toast.error("An unexpected error occurred");
        }
      } else {
        toast.error("You are not eligible to sign up");
      }
    }
  });

  return (
    <div className="w-full box-border lg:w-[614px] bg-white">
      <div className="text-center">
        {referrer === "appointment" ? (
          <p className="text-[24px] font-[800] text-[#103C1B]">
            To continue booking, create your profile or login if you have an
            account.
          </p>
        ) : (
          <p className="text-[34px] font-[800] text-[#103C1B]">
            Create an account
          </p>
        )}
        <Spacer height={10} />
        {referrer === "appointment" ? (
          <p className="font-[400] text-[20px] text-[#103C1B]">
            Let's get you all set up for your appointment
          </p>
        ) : (
          <p className="font-[400] text-[20px] text-[#103C1B]">
            You are a couple of clicks away from access to quality medical care
          </p>
        )}
      </div>
      <Spacer height={40} />
      <div className="flex flex-col mx-auto items-center w-full lg:w-[478px]">
        {signupError?.length > 0 && (
          <p className="bg-[#fdf6f6] text-[#c20707] border-[#E45B3D] border-[1px] p-[10px] text-[18px] rounded-md mb-4">
            Sign up failed: {signupError}
          </p>
        )}
        <TextField
          name="email"
          type="email"
          placeholder="name@mail.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          label="Email address"
          error={formik.touched.email ? formik.errors.email : ""}
        />

        <Spacer height={25} />
        <div className="w-full">
          <span className="flex justify-start">
            <label
              className="text-[#000] font-medium mb-2 text-[16px]"
              htmlFor={`grid`}
            >
              Date Of Birth
            </label>
          </span>
          <div className="w-full bg-white border border-[#CECFC5]">
            <DatePicker
              selected={formik.values.dateOfBirth}
              name="dateOfBirth"
              dateFormat="MM/dd/yyyy"
              onChange={(date: Date) => {
                formik.setFieldValue("dateOfBirth", date);
                const currentDate = new Date();
                const age = differenceInYears(currentDate, date);
                if (age < 18 && age >= 16) {
                  setIsMinor(true);
                  setCanSignup(true);
                  setDateError("");
                } else if (age >= 18) {
                  setIsMinor(false);
                  setCanSignup(true);
                  setDateError("");
                } else {
                  setCanSignup(false);
                  setDateError("please enter a valid date");
                }
              }}
              placeholderText="MM/DD/YYYY"
              className="date-picker"
              forceShowMonthNavigation={true}
              showYearDropdown={true}
              showMonthDropdown={true}
              scrollableMonthYearDropdown={true}
              yearDropdownItemNumber={100}
              scrollableYearDropdown={true}
              shouldCloseOnSelect={true}
              required
            />
          </div>
          <div className="text-red-600">
            {formik.touched.dateOfBirth ? (
              <> {formik.errors?.dateOfBirth ?? ""}</>
            ) : (
              ""
            )}
          </div>
        </div>
        <Spacer height={25} />

        <PasswordField
          name="password"
          placeholder="At least 8 characters"
          value={formik.values.password}
          onChange={formik.handleChange}
          label="Password"
          showPasswordToggler={true}
          error={formik.touched.password ? formik.errors.password : ""}
        />
        <div className="w-[100%] flex justify-between items-center mt-8 text-xs text-normal">
          <div className="flex justify-end w-full">
            <Button
              onClick={formik.submitForm}
              additionalClassname={"text-[18px] font-[400]"}
              width={178}
              height={60}
              type="submit"
              variant="primary"
              label="Create Account"
              disabled={isPending}
            />
          </div>
        </div>
        <div className="lg:h-[80px] h-[40px]" />
        <div className="border-[1px] border-gray-200 w-[300px]" />
        <div className="lg:h-[80px] h-[40px]" />
        <p className="text-center font-[400]">
          Do you have an account?{" "}
          <span className="text-[#1A9D39]">
            {" "}
            <Link to={loginLink}>Sign in</Link>{" "}
          </span>
        </p>
        <div className="lg:h-[20px] hidden lg:block" />
      </div>
    </div>
  );
};

export default SignupFormOne;
