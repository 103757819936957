import { useFormik } from "formik";
import * as Yup from "yup";
import { useCreateDependent } from "../../../api/hooks/patient/createDependent";
import { removeEmptyObjectValues } from "../../../utils/helpers";
import CustomDrawer from "../../drawer";
import AddDependantForm from "./AddDependantForm";

interface AddNewDependantDrawerProps {
  drawerTitle: string;
  onClose: () => void;
  open: boolean;
}

const AddNewDependantDrawer = ({
  drawerTitle,
  onClose,
  open,
}: AddNewDependantDrawerProps) => {

  const { createDependant, isPending } = useCreateDependent();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      gender: "",
      dependentRelationship: "",
      dateOfBirth: new Date() 
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid Email"),
      dependentRelationship: Yup.string().required(
        "Dependent relationship is required"
      ),
      dateOfBirth: Yup.string().required("Date of birth is required")
    }),
    onSubmit: async () => {
      const formattedData = removeEmptyObjectValues({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        gender: formik.values.gender,
        dependentRelationship: formik.values.dependentRelationship,
        dateOfBirth: formik.values.dateOfBirth
      });
      createDependant({
        firstName: formattedData?.firstName,
        lastName: formattedData?.lastName,
        email: formattedData?.email,
        gender: formattedData?.gender,
        dependentRelationship: formattedData?.dependentRelationship,
        dateOfBirth: formattedData?.dateOfBirth
      }, {
        onSuccess: () => {
          formik.resetForm();
          onClose();
        }
      });

    }
  }); 

  const { values, touched, errors, setFieldValue, handleSubmit, handleChange } = formik;


  return (
    <div className="w-full lg:w-[600px]">
      <CustomDrawer
        noWidth
        open={open}
        onClose={onClose}
        drawerTitle={drawerTitle}
      >
        <AddDependantForm
          onCloseModal={onClose}
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          loading={isPending}
        />
      </CustomDrawer>
    </div>
  );
};

export default AddNewDependantDrawer;
