import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { updateToast } from "../../../utils/toast_notification";

type Payload = {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  dependantId: string;
  dependentRelationship: string;
}

export const useUpdateDependent = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['update dependant'],
    mutationFn: async ({ dependantId, ...data }: Payload) => {
      const response = await http.patch(`patients/dependent/${dependantId}`, data);
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;

      updateToast(toastId, "updated successfully", "success");

      queryClient.invalidateQueries({ queryKey: ['patient dependents'] });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      const errorMessage: string = axios.isAxiosError(error)
        ? error?.response?.data?.error || "Error updating dependant"
        : error?.message || "Error updating dependant";

      updateToast(toastId, errorMessage, "error");
    }
  });

  return {
    updateDependent: mutate,
    ...rest,
  };
}
