import axios, { isAxiosError } from "axios";
import { addMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { syncUserCalendar } from "../../../api/common";
import { useCreateOrUpdateProviderSchedule } from "../../../api/hooks/provider/useCreateOrUpdateProviderSchedule";
import { useGetProviderProfileDetails } from "../../../api/hooks/provider/useGetProviderProfileDetails";
import { useGetProviderSchedule } from "../../../api/hooks/provider/useGetProviderSchedule";
import { useTriggerCalendarAuthCode } from "../../../api/hooks/provider/useTriggerSaveCalendarAuthCode";
import { TimeRange } from "../../../api/types";
import Button from "../../../components/shared/Button";
import CustomDropdown from "../../../components/shared/CustomDropdown";
import { Loading } from "../../../components/shared/Loading";
import ToggleSwitch from "../../../components/shared/ToggleSwitch";
import { DeleteIcon } from "../../../svgs/DeleteIcon";
import { PlusWithCircleIcon } from "../../../svgs/PlusWithCircleIcon";

interface DayParam {
  day: string;
  lists: string[][];
  fn: (newTimes: string[][]) => void;
  status: boolean;
}

// Convert time range to UTC
const convertTimeRange = ({
  timeRange,
  offset,
  toUTC
}: {
  timeRange: TimeRange;
  offset: number;
  toUTC: boolean;
}) => {
  const previousDay: Array<string> = [];
  const sameDay: Array<string> = [];
  const nextDay: Array<string> = [];
  const adjustedOffset = toUTC ? -offset : offset;

  timeRange.forEach((time, index) => {
    const [hours, minutes] = time.split(":").map(Number);
    let isPrevDay = false;
    let isNextDay = false;

    let totalMinutes = hours * 60 + minutes;

    totalMinutes -= adjustedOffset;

    if (totalMinutes < 0) {
      totalMinutes = 24 * 60 + totalMinutes;
      isPrevDay = true;
    } else if (totalMinutes >= 1440) {
      isNextDay = true;
    }

    const newHours = Math.floor(totalMinutes / 60) % 24;
    const newMinutes = totalMinutes % 60;
    
    const formatTime = (unit: number) => unit.toString().padStart(2, "0");
    const newTime = `${formatTime(newHours)}:${formatTime(newMinutes)}`;
    if (isPrevDay) {
      previousDay.push(newTime);
    } else if (
      !isPrevDay &&
      !isNextDay &&
      index === 1 &&
      previousDay.length === 1
    ) {
      previousDay.push("00:00");
      if (newTime !== "00:00") {
        sameDay.push(...["00:00", newTime]);
      }
    } else if (isNextDay && index === 1 && sameDay.length === 1) {
      sameDay.push("00:00");
      if (newTime !== "00:00") {
        nextDay.push(...["00:00", newTime]);
      }
    } else if (isNextDay) {
      nextDay.push(newTime);
    } else {
      sameDay.push(newTime);
    }
  });
  return { previousDay, sameDay, nextDay };
};

// Process the schedule for a specific day
const processDaySchedule = ({
  day,
  schedule,
  offset,
  dayMap,
  toUTC
}: {
  day: string;
  schedule: Array<TimeRange>;
  offset: number;
  dayMap: { [key: string]: Array<Array<string>> };
  toUTC: boolean;
}) => {
  schedule?.forEach(timeRange => {
    const { previousDay, sameDay, nextDay } = convertTimeRange({
      timeRange,
      offset,
      toUTC
    });

    if (previousDay.length) {
      const prevDay = getPreviousDay(day);
      dayMap[prevDay].unshift(previousDay);
    }
    if (sameDay.length) {
      dayMap[day].push(sameDay);
    }
    if (nextDay.length) {
      const nextDayName = getNextDay(day);
      dayMap[nextDayName].push(nextDay);
    }
  });
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

// Get previous and next day
const getPreviousDay = (day: string): string => {
  const index = days.indexOf(day);
  return days[(index + 6) % 7];
};

const getNextDay = (day: string): string => {
  const index = days.indexOf(day);
  return days[(index + 1) % 7];
};

const Calendar = () => {
  const searchParams = useSearchParams()[0];
  const authorizationCode = searchParams.get("code");
  const [selectedFirstAppt, setSelectedFirstAppt] = useState<number>(60);
  const [selectedFollowUpAppt, setSelectedFollowupAppt] = useState<number>(30);
  const [dayTimes, setDayTimes] = useState<{ [key: string]: string[][] }>({
    Sunday: [["00:00", "00:00"]],
    Monday: [["00:00", "00:00"]],
    Tuesday: [["00:00", "00:00"]],
    Wednesday: [["00:00", "00:00"]],
    Thursday: [["00:00", "00:00"]],
    Friday: [["00:00", "00:00"]],
    Saturday: [["00:00", "00:00"]]
  });

  const [isAuthorizingCalendar, setIsAuthorizingCalendar] =
    useState<boolean>(false);
  const {
    providerData,
    isLoading: isProviderLoading,
    isError: isProviderError,
    error: providerError
  } = useGetProviderProfileDetails();
  const { createOrUpdateProviderSchedule, isPending } =
    useCreateOrUpdateProviderSchedule();

  const submitSchedule = () => {
    const inputError = Object.values(timeError).find(
      (item: any) => item.length
    );
    if (inputError && typeof inputError === "string") {
      toast.error(inputError, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        toastId: "create-update-schedule-err"
      });
      return;
    }

    const schedule = days
      .map(
        day =>
          dayStatus[day] && {
            day,
            schedule: dayTimes[day].filter(times => times.length === 2)
          }
      )
      .filter(Boolean);

    const dayMap: { [key: string]: Array<Array<string>> } = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: []
    };

    const offset = new Date().getTimezoneOffset();
    schedule.forEach(item => {
      if (item) {
        // Check if item is not false
        const validSchedule = item.schedule.filter(
          times => times.length === 2
        ) as TimeRange[]; // Ensure each schedule has 2 elements
        processDaySchedule({
          day: item.day,
          schedule: validSchedule,
          offset,
          dayMap,
          toUTC: true
        });
      }
    });

    createOrUpdateProviderSchedule({
      firstCallDuration: selectedFirstAppt,
      followUpCallDuration: selectedFollowUpAppt,
      ...Object.fromEntries(
        Object.entries(dayMap).filter(([_, value]) => value.length)
      ),
      offset
    });
  };

  const {
    refetch,
    error: CalError,
    isError: isCalError
  } = useTriggerCalendarAuthCode(String(authorizationCode));

  if (isCalError) {
    const message = axios.isAxiosError(CalError)
      ? CalError?.response?.data?.error
      : "Error processing request";
    toast.error(message, { toastId: "customId" });
  }

  useEffect(() => {
    if (authorizationCode) {
      refetch();
    }
  }, [refetch, authorizationCode]);

  const { schedule, isError, error, isLoading } = useGetProviderSchedule();

  useEffect(() => {
    if (schedule) {
      setSelectedFirstAppt(schedule.firstCallDuration);
      setSelectedFollowupAppt(schedule.followUpCallDuration);

      const dayMap: { [key: string]: Array<Array<string>> } = {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: []
      };

      schedule.schedule.forEach(
        ({ day, schedule }: { day: string; schedule: TimeRange[] }) => {
          processDaySchedule({
            day,
            schedule,
            offset: new Date().getTimezoneOffset(),
            dayMap,
            toUTC: false
          });
        }
      );

      setDayTimes(prev => ({
        ...prev,
        ...Object.fromEntries(
          Object.entries(dayMap).map(([day, times]) => [day, times.sort()])
        )
      }));
    }
  }, [schedule]);

  if (isError) {
    const message = axios.isAxiosError(error)
      ? error?.response?.data?.error
      : "Error processing request";
    toast.error(message, { toastId: "customId" });
  }

  const [timeError, setTimeError] = useState<{ [key: string]: string }>({
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
    Sunday: ""
  });

  const [dayStatus, setDayStatus] = useState<{ [key: string]: boolean }>({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true
  });

  const selectTimes = Array.from(Array(97).keys()).map(value => {
    const newTime = addMinutes(new Date(2023, 3, 3, 0, 0), 15 * value)
      .toTimeString()
      .slice(0, 5);
    return { name: newTime, value: newTime };
  });

  const dayParams: Array<DayParam> = days.map(day => ({
    day,
    lists: dayTimes[day],
    fn: (newTimes: string[][]) =>
      setDayTimes(prev => ({ ...prev, [day]: newTimes })),
    status: dayStatus[day]
  }));

  if (isLoading || isProviderLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }

  if (isError || isProviderError) {
    const errorMessage = isError ? error : providerError;
    if (isAxiosError(errorMessage)) {
      const message = errorMessage?.response?.data?.error;
      toast.error(message, { toastId: "customId" });
    } else {
      toast.error(errorMessage?.error, { toastId: "customId" });
    }
  }
  // Validate time ranges
  const validateTime = (endTime: string, startTime: string, day: string) => {
    if (
      endTime.replace(":", "") < startTime.replace(":", "") &&
      endTime !== "00:00"
    ) {
      setTimeError(prev => ({
        ...prev,
        [day]: "Choose an end time later than the start time"
      }));
    } else {
      setTimeError(prev => ({ ...prev, [day]: "" }));
    }
  };
  return (
    <>
      <div className="h-full w-full mb-10 overflow-y-auto">
        <h1 className="font-[600] text-[20px] text-black pl-4 pb-2">
          Set Availability
        </h1>
        <div className="p-4">
          <p className="pb-2 font-[500]">Sync Your Calendars</p>
          {providerData?.user?.googleRefreshToken ? (
            <p>Google calendar synced</p>
          ) : (
            <div className="w-[122px]">
              <Button
                variant="secondary"
                label="+ Add Calendar"
                textColor="#3D874E"
                size="medium"
                onClick={async () => {
                  setIsAuthorizingCalendar(true);
                  const data = await syncUserCalendar({
                    type: "google",
                    user: "provider"
                  });
                  setIsAuthorizingCalendar(false);
                  if (data?.url) {
                    window.open(data.url, "myWindow", "width=800,height=600");
                  }
                }}
                loading={isAuthorizingCalendar}
              />
            </div>
          )}
          <p className="pt-6 pb-3 font-[500]">Set Appointment Duration</p>
          <div className="w-full lg:w-[40%] xl:w-[34%] 2xl:w-[28%] flex items-center justify-start">
            <div>First Appointment</div>
            <div className="w-[1/2] ml-16">
              <CustomDropdown
                placeholder="60 mins"
                defaultValue="60 mins"
                name="firstAppointment"
                value={selectedFirstAppt}
                onChange={e => setSelectedFirstAppt(Number(e.target.value))}
                optionsList={[
                  { name: "15 mins", value: "15" },
                  { name: "30 mins", value: "30" },
                  { name: "45 mins", value: "45" },
                  { name: "60 mins", value: "60" },
                  { name: "75 mins", value: "75" },
                  { name: "90 mins", value: "90" }
                ]}
              />
            </div>
          </div>
          <div className="w-full lg:w-[40%] xl:w-[34%] 2xl:w-[28%] flex py-5 items-center justify-start">
            <div>Follow-up Appointment</div>
            <div className="w-[1/2] ml-6">
              <CustomDropdown
                placeholder="30 mins"
                defaultValue="30 mins"
                name="followUpAppointment"
                value={selectedFollowUpAppt}
                onChange={e => setSelectedFollowupAppt(Number(e.target.value))}
                optionsList={[
                  { name: "15 mins", value: "15" },
                  { name: "30 mins", value: "30" },
                  { name: "45 mins", value: "45" },
                  { name: "60 mins", value: "60" },
                  { name: "75 mins", value: "75" },
                  { name: "90 mins", value: "90" }
                ]}
              />
            </div>
          </div>
          <p className="py-2 font-[500]">Available Days & Times</p>
          <div>
            {dayParams.map(({ day, lists, fn, status }, index) => (
              <div key={index}>
                <div className="flex flex-wrap lg:flex-nowrap w-full lg:w-[82%] items-center xl:w-[68%] 2xl:w-[65%]">
                  <div className="self-start pt-2 w-full lg:w-[200px]">
                    <ToggleSwitch
                      label={day}
                      isSelected={status && lists.length > 0}
                      onClick={() => {
                        setDayStatus(prev => ({ ...prev, [day]: !status }));
                        if (!lists.length) {
                          fn([["00:00", "00:00"]]);
                        }
                      }}
                    />
                  </div>
                  <div className="flex justify-start items-center w-full">
                    <div className="py-3 w-full lg:w-[320px]">
                      {lists.length && status ? (
                        lists.map((list: any, index: number) => (
                          <div className="flex items-center py-2" key={index}>
                            <div className="w-[30] pr-4">
                              <CustomDropdown
                                placeholder="00:00"
                                defaultValue="00:00"
                                name="availability"
                                value={list[0]}
                                onChange={e => {
                                  const newList = [...list];
                                  newList[0] = e.target.value;
                                  fn(
                                    lists.map((value: any, ind: any) =>
                                      ind === index ? newList : value
                                    )
                                  );
                                  validateTime(list[1], newList[0], day);
                                }}
                                optionsList={selectTimes}
                              />
                            </div>
                            <div>&rarr;</div>
                            <div className="w-[30] px-4">
                              <CustomDropdown
                                placeholder="00:00"
                                defaultValue="00:00"
                                name="availability"
                                value={list[1]}
                                onChange={e => {
                                  const newList = [...list];
                                  newList[1] = e.target.value;
                                  fn(
                                    lists.map((value: any, ind: any) =>
                                      ind === index ? newList : value
                                    )
                                  );
                                  validateTime(newList[1], newList[0], day);
                                }}
                                optionsList={selectTimes}
                              />
                            </div>
                            <div
                              className="px-4"
                              onClick={() => {
                                fn(
                                  lists.filter(
                                    (item: any, ind: number) => ind !== index
                                  )
                                );
                                if (lists.length === 1) {
                                  setDayStatus(prev => ({
                                    ...prev,
                                    [day]: false
                                  }));
                                }
                              }}
                            >
                              <DeleteIcon className="cursor-pointer" />
                            </div>
                            <div className="lg:justify-self-start">
                              <div
                                onClick={() => {
                                  if (!status) {
                                    setDayStatus(prev => ({
                                      ...prev,
                                      [day]: !status
                                    }));
                                  } else {
                                    fn([
                                      ...lists,
                                      [
                                        lists.length
                                          ? lists[lists.length - 1][1]
                                          : "00:00",
                                        "00:00"
                                      ]
                                    ]);
                                  }
                                }}
                              >
                                <PlusWithCircleIcon className="cursor-pointer" />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="pt-4">Unavailable</p>
                      )}
                    </div>
                  </div>
                </div>
                {timeError[day] && (
                  <p className="text-red-500">{timeError[day]}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky">
        <div className="h-full flex justify-end items-center">
          <div className="w-[122px] lg:mr-5">
            <Button
              type="button"
              variant="primary"
              label="Save changes"
              size="medium"
              loading={isPending}
              onClick={submitSchedule}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
