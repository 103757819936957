import { useQuery } from '@tanstack/react-query';
import billerHttp from '../../../utils/billerHttp';
import { ErrorResponse, } from '../../types';
import { FilterProvider, FilterProvidersResponse } from './types/biller.types';


export function useSearchProviderForBillers() {
  const { data, isLoading, ...rest } = useQuery<FilterProvidersResponse, ErrorResponse>({
    queryKey: ["search biller providers"],
    queryFn: async () => {
      const response = await billerHttp.get(`billers/providers/search`);
      return response.data;
    },
    staleTime: Infinity,
  });
  return {
    searchedProvidersList: data?.filterProviders as FilterProvider[],
    isLoading,
    ...rest,
  }
}