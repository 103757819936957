import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { Patient } from "../../../types/patientType";
import http from "../../../utils/http";


type Payload = {
  data: Partial<Patient>[];
}

export const useAddPatientForProvider = () => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['create single provider patient'],
    mutationFn: async ({ data }: Payload) => {
      const response = await http.post(`/providers/patient`, {
        fileValues: data
      })
      return response.data;
    },

    onSuccess: () => {
      toast.success("Patient successfully added.");
      queryClient.invalidateQueries({
        queryKey: ["providers patients"]
      });
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        toast.error(
          error?.response?.data?.error || 'Error saving patient details. Please try again later',
        );
      } else {
        toast.error(
          error?.message
          || "Error saving patient details. Please try again later",
        );
      }
    },
  });

  return {
    addPatientForProvider: mutate,
    newPatient: data,
    variables,
    ...rest,
  };
}
