import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';
import { ErrorResponse } from '../../types';
import { Dependents } from '../accounts/types/dependant.type';


export function useGetPatientDependents() {
  const { data, refetch, ...rest } = useQuery<Dependents, ErrorResponse>({
    queryKey: ['patient dependants'],
    queryFn: async () => {
      const response = await http.get("patients/dependents")
      return response.data;
    }
  });
  return {
    patientDependents: data,
    ...rest,
  }
}





