import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";


type Payload = {
  avatarUrl?: string;
  governmentIdUrl?: string;
  uploadedFileUrl?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  pronoun?: string;
  zipCode?: string;
  gender?: string;
  stateOfIdIssue?: string;
  idNumber?: string;
  address?: string;
  state?: string;
  city?: string;
  ssn?: string;
  maritalStatus?: string;
  race?: string;
  insuranceName?: string;
  insuranceNumber?: string;
}



export const useUpdatePatientDetails = () => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation({
    mutationKey: ['update patient details'],
    mutationFn: async ({ ...data }: Payload) => {
      const response = await http.patch("patients/details", data)
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (_data, _variables, context) => {
      const { toastId } = context;
      toast.update(toastId, {
        render: "Patient details updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      })
      queryClient.invalidateQueries({ queryKey: ['patient profile details'] });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number

      if (axios.isAxiosError(error)) {
        toast.update(toastId, {
          render: error?.response?.data?.error || "Error updating patient details",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        })

      } else {
        toast.update(toastId, {
          render: error?.message || "Error updating patient details",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

      }
    }
  });

  return {
    updatePatientData: mutate,
    ...rest,
  };
}
